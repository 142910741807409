/**
 * Formats crypto values for display, using subscript notation for very small values
 * to save space in the UI.
 */
import React from "react";
import fixString from "./fixString";
import formatNumber from "./formatNumber";
/**
 * Regular JavaScript function that formats a crypto value with subscript notation
 *
 * @param {number} value - The crypto value to format
 * @param {object} options - Formatting options
 * @param {number} options.threshold - Values smaller than this will use subscript notation (default: 0.00001)
 * @param {number} options.maxDecimals - Maximum decimal places to show (default: 8)
 * @param {number} options.significantDigits - Number of significant digits to show for small values (default: 2)
 * @param {boolean} options.useGrouping - Whether to use thousands separator (default: true)
 * @returns {string} HTML string with formatted value (may contain <sub> tags)
 */
export function formatCryptoValue(value, options = {}) {
  // Default options
  const {
    threshold = 0.00001,
    maxDecimals = 6,
    significantDigits = 3,
    useGrouping = true,
  } = options;

  // Handle null/undefined/NaN
  if (value == null || isNaN(value)) {
    return "0";
  }

  // Convert to number if string
  const numValue = typeof value === "string" ? parseFloat(value) : value;

  // Handle zero
  if (numValue === 0) {
    return "0";
  }

  // For values above threshold, use regular formatting
  if (Math.abs(numValue) >= threshold) {
    return (
      // fixString(
      Number(numValue).toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: maxDecimals,
        useGrouping,
      })
      // maxDecimals
      // ) || "0"
    );
  }

  // For very small values, use subscript notation
  // Find the first non-zero digit after decimal
  const stringValue = formatNumber(Math.abs(numValue), 20);
  const decimalPart = stringValue.split(".")[1];
  let zeroCount = 0;
  // Count leading zeros
  for (let i = 0; i < decimalPart.length; i++) {
    if (decimalPart[i] === "0") {
      zeroCount++;
    } else {
      break;
    }
  }

  // Extract significant digits based on the specified parameter
  const significantPart = decimalPart.substring(
    zeroCount,
    zeroCount + significantDigits
  );

  // Create formatted value with subscript for zero count
  // Example: 0.0000000123 becomes 0.0<sub>7</sub>123 (with a space and showing zeroCount)
  const sign = numValue < 0 ? "-" : "";
  return `${sign}0.0<sub>${zeroCount}</sub>${significantPart}`;
}

/**
 * React component that renders a formatted crypto value with proper subscript
 *
 * @param {object} props - Component props
 * @param {number} props.value - The crypto value to format
 * @param {object} props.options - Same options as formatCryptoValue function
 * @returns {JSX.Element} Rendered component with properly formatted value
 */
export function FormatCryptoValue({ value, options = {} }) {
  const formattedValue = formatCryptoValue(value, options);
  // If no HTML tags, just return the value
  if (!formattedValue || formattedValue === "0") {
    return <>0</>;
  }
  if (!formattedValue?.includes("<sub>")) {
    return <>{fixString(formattedValue, options.maxDecimals)}</>;
  }

  // Parse the HTML and render it properly
  const [beforeSub, rest] = formattedValue?.split("<sub>");
  const [subValue, afterSub] = rest?.split("</sub>");

  return (
    <>
      {beforeSub}
      <sub>{subValue}</sub>
      {afterSub}
    </>
  );
}

/**
 * A simpler version that returns a plain text representation
 * (for use in attributes or where HTML is not supported)
 *
 * @param {number} value - The crypto value to format
 * @param {object} options - Same options as formatCryptoValue
 * @returns {string} Plain text representation of the value
 */
export function formatCryptoValuePlainText(value, options = {}) {
  const {
    threshold = 0.00001,
    maxDecimals = 8,
    significantDigits = 3,
    useGrouping = true,
  } = options;

  if (value == null || isNaN(value)) {
    return "0";
  }

  const numValue = typeof value === "string" ? parseFloat(value) : value;

  if (numValue === 0) {
    return "0";
  }

  if (Math.abs(numValue) >= threshold) {
    return Number(numValue).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: maxDecimals,
      useGrouping,
    });
  }

  // For very small values, use a modified scientific notation to match our subscript format
  const stringValue = Math.abs(numValue).toFixed(20);
  const decimalPart = stringValue.split(".")[1];
  let zeroCount = 0;

  // Count leading zeros
  for (let i = 0; i < decimalPart.length; i++) {
    if (decimalPart[i] === "0") {
      zeroCount++;
    } else {
      break;
    }
  }

  // Extract significant digits
  const significantPart = decimalPart.substring(
    zeroCount,
    zeroCount + significantDigits
  );

  return `0.0 e-${zeroCount - 1} ${significantPart}`;
}
