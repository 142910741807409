function restrictChars(e) {
  const allowedKeys = [
    "Tab",
    "Enter",
    "Backspace",
    "Delete",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    ".",
  ];
  if (/^\d$/.test(e.key)) {
    return;
  }
  if (!allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
}

export default restrictChars;
