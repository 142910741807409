import React, { useState, useEffect } from "react";
import useStore from "../zustand/store";
import { Connection, PublicKey } from "@solana/web3.js";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { useBalance } from "wagmi";
import convertNative from "../utils/convertNative";
import { useAccount } from "wagmi";
import getSolBalancesSpl from "../utils/getSolBalanceSpl";
import {
  useBalance as useBalanceGraz,
  useAccount as useAccountGraz,
  useBalances,
  useStargateClient,
  useStargateSigningClient,
} from "graz";
import { keyBy } from "lodash";
import parseAddress from "../utils/parseAddress";
function useGetBalance(isTransactionSuccessful, enableEvm) {
  const [nonNativeBalance, setNonNativeBalance] = useState(0);
  const { primaryWallet, setPrimaryWallet } = useDynamicContext();
  const [isBalanceLoading, setIsBalanceLoading] = useState(false);
  const fromChain = useStore((state) => state.fromChain);
  const { address, isConnected } = useAccount();
  const cosmosChainIds = useStore((state) => state.cosmosChainIds);
  const { data: datagraz, isConnected: isConnectedGraz } = useAccountGraz({
    chainId: cosmosChainIds,
    multiChain: true,
  });
  const fromCoin = useStore((state) => state.fromCoin);
  const { data, isLoading } = useBalance({
    address: address,
    token: convertNative(fromCoin?.address || ""),
    chainId: Number(fromChain?.chainId || 0),
    enabled:
      (fromChain.networkType == "evm" || enableEvm) &&
      address &&
      address?.length
        ? true
        : false,
  });
  const {
    data: client,
    isLoading: isClientLoading,
    isFetching,
    isInitialLoading,
    isRefetching,
  } = useStargateClient({
    chainId: [fromChain.chainId],
    multiChain: true,
  });
  async function getNativeBal() {
    if (!primaryWallet) return;

    const connection = new Connection("https://solana-rpc.publicnode.com");

    if (!connection) return;

    try {
      const publicKey = new PublicKey(primaryWallet.address);
      setIsBalanceLoading(true);
      const balanceSol = await connection.getBalance(publicKey);
      const balanceSolVal = balanceSol / 1000000000; // Convert lamports to SOL
      setNonNativeBalance(balanceSolVal);
      setIsBalanceLoading(false);
    } catch (e) {
      setIsBalanceLoading(false);
    }
  }
  async function getSpl(address) {
    try {
      setIsBalanceLoading(true);
      let res = await getSolBalancesSpl(primaryWallet.address);
      setIsBalanceLoading(false);
      setNonNativeBalance(res?.[fromCoin?.address]);
    } catch (err) {
      console.log(err);
      setIsBalanceLoading(false);
    }
  }
  let eventId;

  async function setEvent() {
    if (!primaryWallet) return;
    const connection = await primaryWallet.connector.getPublicClient();
    if (!connection) return;
    const publicKey = new PublicKey(primaryWallet.address);
    if (!publicKey) return;
    eventId = connection.onAccountChange(publicKey, (e) => {
      if (fromChain.networkType == "sol" && primaryWallet?.address) {
        if (fromCoin?.symbol?.toLowerCase() === "sol") {
          getNativeBal();
        } else {
          getSpl();
        }
      }
    });
  }
  useEffect(() => {
    fromChain?.networkType === "sol" && setEvent();
    return async () => {
      if (!primaryWallet) return;
      const connection = await primaryWallet.connector.getPublicClient();
      connection.removeAccountChangeListener(eventId);
    };
  }, [primaryWallet?.address, fromChain?.networkType, fromCoin]);
  async function setCosmosBal(chainId) {
    let parsedClient = client[chainId] || client;
    try {
      setIsBalanceLoading(true);
      let balance = await parsedClient.getAllBalances(
        datagraz?.[chainId]?.bech32Address
      );
      let balObj = keyBy(balance, "denom");
      setNonNativeBalance(
        balObj[parseAddress(fromCoin.address)]?.amount /
          Math.pow(10, fromCoin.decimals) || 0
      );
      setIsBalanceLoading(false);
    } catch (err) {
      console.log(err, "balerrorcos");
      setIsBalanceLoading(false);
    }
  }
  useEffect(() => {
    if (fromChain.networkType == "sol" && primaryWallet?.address) {
      if (fromCoin?.symbol?.toLowerCase() === "sol") {
        getNativeBal();
      } else {
        getSpl();
      }
    } else if (fromChain.networkType == "evm" && address && address?.length) {
      setNonNativeBalance(Number(data?.formatted || 0));
    } else if (
      fromChain.networkType == "cosmos" &&
      isConnectedGraz &&
      !isClientLoading
    ) {
      setCosmosBal(fromChain?.chainId);
    }
  }, [
    fromChain.networkType,
    fromCoin?.address,
    data,
    address,
    primaryWallet?.address,
    address,
    isConnectedGraz,
    isClientLoading,
    isTransactionSuccessful,
  ]);
  return {
    getbal: nonNativeBalance,
    isBalanceLoading:
      fromChain?.networkType == "sol" || fromChain?.networkType == "cosmos"
        ? isBalanceLoading
        : fromChain?.networkType == "evm"
        ? isLoading
        : false,
  };
}

export default useGetBalance;
