import React, { useEffect, useState } from "react";
import useStore from "../../zustand/store";
import {
  useAccount,
  useDisconnect,
  useSwitchAccount,
  useConnectors,
  useConnections,
  useConnect,
  useConfig,
} from "wagmi";
import getIsInstalled from "../SelectWallet/getisInstalled";
import InstalledIcon from "../InstalledIcon";
import images from "../../images";
import { keyBy } from "lodash";
import DisconnectWallet from "../Svg/disconnectWallet";
import SupportedWallets from "../SelectWallet/SupportedWallets";
function EvmWallet({
  selectedWalletTab,
  styles,
  connectWalletField,
  handleShowWallet,
  setSelectedConnector,
  setSelectedWallet,
  handleConnect,
  setShowLoading,
  section,
}) {
  const configuration = useStore((state) => state.configuration);
  const setToDisconnect = useStore((state) => state.setToDisconnect);
  const { isConnected, connector } = useAccount();
  const { disconnect } = useDisconnect();
  const [connectorObj, setConnectorObj] = useState();
  const [evmSupports, setEvmSupports] = useState([]);
  const { connectors: switchC, switchAccountAsync } = useSwitchAccount();
  const {
    injectedicon,
    metamaskicon,
    coinbaseicon,
    walletconnecticon,
    ethereumicon,
    disconnecticon,
    disconnectnew,
  } = images;
  const walletIcons = {
    injected: injectedicon,
    metaMaskSDK: metamaskicon,
    coinbaseWalletSDK: coinbaseicon,
    walletConnect: walletconnecticon,
  };
  const config = useConfig();
  const connectors = useConnectors({
    config,
  });
  const connections = useConnections();
  const setIsEvmSupportAvailable = useStore(
    (state) => state.setIsEvmSupportAvailable
  );
  let supportArray = ["MetaMask", "Trust Wallet", "Rabby Wallet", "Phantom"];

  useEffect(() => {
    let connectorObj = keyBy(connectors, "name");
    let evmSupports = supportArray.filter((item) => !connectorObj[item]);
    setEvmSupports(evmSupports);
    setIsEvmSupportAvailable(evmSupports.length > 0);
  }, [connectors]);
  useEffect(() => {
    setConnectorObj(keyBy(connections, (item) => item.connector.uid));
  }, [connections]);
  return (
    <>
      {selectedWalletTab == "All" || selectedWalletTab == "EVM" ? (
        connectors
          .filter((item) => {
            let isConnected =
              connector?.uid == item.uid || connectorObj?.[item.uid];
            return (section == "connected" && isConnected) ||
              (section == "installed" && !isConnected) ||
              section == "supported"
              ? true
              : false;
          })
          .map((item, i) => {
            return (
              <>
                {section !== "supported" ? (
                  <div
                    style={
                      connector?.id == item?.id
                        ? {
                            ...configuration?.gradientStyle,
                          }
                        : {}
                    }
                    className={`bw-w-[47%] bw-mb-4  bw-relative bw-rounded-md bw-p-[1px] ${
                      !configuration.gradientStyle && connector?.id == item?.id
                        ? styles.gradientbutton
                        : "bw-bg-border-primary"
                    } `}
                  >
                    <div
                      key={i}
                      onClick={() => {
                        if (connector?.id == item?.id) {
                          if (connectWalletField == "to") {
                            setToDisconnect(false);
                            handleShowWallet();
                          } else {
                            disconnect({ connector });
                          }
                        } else {
                          setSelectedWallet(item.name || item.type);
                          setSelectedConnector(item);
                          handleConnect(
                            item,
                            connectorObj?.[item.uid] ? true : false
                          );
                          setShowLoading(false);
                          setToDisconnect(false);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                      className={`bw-w-full bw-bg-background-container hover:bw-shadow-sm bw-font-medium hover:bw-font-bold  hover:bw-text-text-primary  bw-flex bw-gap-x-3  bw-px-3 bw-justify-start bw-items-center bw-h-[56px] ${
                        connector?.uid == item?.uid
                          ? " bw-text-text-primary"
                          : "bw-text-text-secondary"
                      } bw-relative  bw-rounded-md
        ${""}
        `}
                    >
                      {/* {connector?.uid == item.uid || connectorObj?.[item.uid] ? (
                <div
                  className={`bw-text-[10px]  bw-flex bw-items-center bw-gap-x-1 bw-mt-[-2px] bw-font-medium bw-absolute bw-top-1 bw-right-2`}
                >
                  <div
                    style={{ ...configuration?.gradientStyle }}
                    className={`bw-w-[10px] bw-rounded-[50%] ${
                      !configuration?.gradientStyle ? styles.gradientbutton : ""
                    } bw-bg-text-greentext bw-h-[10px]`}
                  ></div>{" "}
                  <p
                    style={{ ...configuration?.gradientStyle }}
                    className={` bw-bg-clip-text bw-text-transparent ${
                      !configuration?.gradientStyle ? styles.gradientbutton : ""
                    } `}
                  >
                    Connected
                  </p>
                </div>
              ) : (
                <div className="bw-absolute bw-top-[2px] bw-right-1">
                  <p className="bw-text-text-secondary bw-text-xs bw-font-normal">
                    Installed
                  </p>
                </div>
              )} */}

                      <div className="bw-w-max bw-relative">
                        {" "}
                        <img
                          className="bw-rounded-md"
                          src={walletIcons[item.id] || item.icon}
                          width={32}
                          height={32}
                          alt="img"
                        />
                        {/* <img
                  src={ethereumicon}
                  className="bw-absolute bw-right-[-15%] bw-bottom-[-30%]"
                  width=""
                  height=""
                  alt="img"
                /> */}
                      </div>

                      <p className="bw-text-xs bw-w-max ">{item.name}</p>
                    </div>
                    <div
                      style={
                        connector?.id == item?.id
                          ? {
                              ...configuration?.gradientStyle,
                            }
                          : {}
                      }
                      className={`bw-mb-4 bw-bottom-[-50%] bw-absolute bw-right-2  bw-rounded-md bw-p-[1px] ${
                        !configuration.gradientStyle &&
                        connector?.id == item?.id
                          ? styles.gradientbutton
                          : "bw-bg-border-primary"
                      } `}
                    >
                      <div className=" bw-w-[71px] bw-h-[25px]  bw-rounded-md bw-flex bw-bg-background-container bw-justify-center bw-items-center bw-gap-x-1  ">
                        <img src={ethereumicon} width="" height="" alt="img" />
                        <p className="bw-text-xs bw-font-bold bw-text-text-primary">
                          EVM
                        </p>
                      </div>
                    </div>
                    {connectorObj?.[item.uid] ? (
                      <div
                        onClick={(e) => {
                          disconnect({
                            connector: connectorObj?.[item.uid]?.connector,
                            config,
                          });
                          e.stopPropagation();
                        }}
                        className=" bw-cursor-pointer bw-absolute bw-right-[5px] bw-top-1 bw-text-[10px] bw-text-text-primary  bw-z-40 bw-rounded-[50%]"
                      >
                        <div
                          style={{
                            boxShadow:
                              (configuration &&
                                configuration.customTheme &&
                                configuration.customTheme?.shadow?.boxShadow) ||
                              "1px 1px 7px rgba(0, 0, 0, 0.20)",
                          }}
                          className="bw-w-[15px] bw-h-[15px] bw-rounded-[50%]"
                        >
                          <DisconnectWallet fill={"var(--tw-text-primary)"} />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            );
          })
      ) : (
        <></>
      )}
      {section === "supported" &&
      (selectedWalletTab == "All" || selectedWalletTab == "EVM") ? (
        evmSupports.map((item) => (
          <SupportedWallets wallet={item} chain={"evm"} />
        ))
      ) : (
        <></>
      )}
    </>
  );
}

export default EvmWallet;
