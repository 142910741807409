import React from "react";
import useStore from "../../zustand/store";
import Copy from "../Svg/copy";
import TxnHash from "../Tooltip/TxnHash";
function TxnErrorComp({ errorText }) {
  const persist = useStore((state) => state.persist);
  return (
    <>
      <p className="bw-text-lg bw-font-medium  bw-text-text-primary bw-mb-1 bw-text-center">
        Transaction Failed
      </p>
      <div className="bw-w-full bw-mb-2 ">
        <p className="bw-text-sm bw-font-bold bw-text-text-primary ">
          Route Id
        </p>
        <div className="bw-flex bw-items-center bw-gap-x-1">
          <p className="bw-text-sm bw-font-normal bw-text-text-secondary">
            {persist?.routeId}
          </p>
          <TxnHash txnHash={persist?.routeId}>
            <div>
              <Copy fill={"var(--tw-text-secondary)"} />
            </div>
          </TxnHash>
        </div>
      </div>
      <div className="bw-text-left bw-w-full bw-mb-2 ">
        <p className="bw-text-sm bw-font-bold bw-text-text-primary ">Error</p>
        <div className="bw-text-left  bw-max-h-[60px] bw-overflow-auto ">
          <p
            style={{ wordBreak: "break-all" }}
            className="bw-text-xs bw-leading-5 bw-pr-2 bw-w-full md:bw-max-w-[420px]  bw-font-normal bw-mb-2 bw-text-left bw-text-text-redtext"
          >
            {errorText}
          </p>
        </div>
      </div>
    </>
  );
}

export default TxnErrorComp;
