import truncate from "../../utils/truncate";
import TabComp from "./TabComp";
import RoutesBack from "../Svg/routesBack";
import useStore from "../../zustand/store";
import images from "../../images";
import fixString from "../../utils/formatNumber";
export default function AllRoutes({
  routes,
  handleShowAllRoutes,
  handleRoutesData,
  convertVal,
  amount,
}) {
  const fromChain = useStore((state) => state.fromChain);
  const fromCoin = useStore((state) => state.fromCoin);
  const toChain = useStore((state) => state.toChain);
  const { defaultCoin } = images;
  return (
    <div>
      <div className="bw-flex bw-w-full bw-relative bw-justify-between bw-mb-2">
        <div className="bw-flex bw-items-start  bw-gap-x-3">
          <button
            onClick={() => {
              handleShowAllRoutes();
            }}
            className="bw-mt-1"
          >
            <RoutesBack
              fill1={"var(--tw-background-secondary)"}
              fill2={"var(--tw-text-secondary)"}
            />
          </button>
          <div className="bw-text-lg bw-font-normal bw-text-text-secondary">
            <div className="">
              <span className="">{routes.data?.data?.quotes?.length || 0}</span>{" "}
              Routes For
            </div>{" "}
            <p className="bw-text-xs bw-font-bold bw-mt-2">{`${fromCoin?.coin} on ${fromChain?.chain}`}</p>
          </div>
        </div>
        <div className=" bw-flex bw-items-center bw-gap-x-2">
          <div>
            <p className="bw-text-2xl bw-font-bold bw-mb-2 bw-leading-none bw-text-right bw-text-text-primary">
              {amount}
            </p>
            <p className="bw-text-sm bw-font-bold bw-text-right bw-text-text-secondary">
              ${fixString(amount * fromCoin?.lastPrice || 0, 10)}
            </p>
          </div>
          <div className="  bw-bg-background-coin bw-flex bw-justify-center bw-items-center bw-mb-2 bw-relative bw-rounded-[50%] bw-w-[35px] bw-h-[35px] bw-border bw-border-border-primary">
            {fromCoin?.image ? (
              <img
                src={fromCoin.image}
                width={34}
                height={34}
                alt="img"
                className="bw-rounded-[50%] bw-max-w-[34px] bw-max-h-[34px]  bw-object-contain bw-min-w-[34px] bw-h-[34px]"
              />
            ) : (
              <img
                src={defaultCoin}
                width={34}
                height={34}
                alt="img"
                className="bw-rounded-[50%] bw-min-w-[34px] bw-h-[34px]"
              />
            )}
            <div className="bw-bg-background-coin bw-absolute bw-bottom-[-30%] bw-right-4 bw-rounded-[50%] bw-w-[19px] bw-h-[19px] bw-border bw-border-border-primary">
              {" "}
              {fromCoin?.image ? (
                <img
                  src={fromChain.image}
                  width={18}
                  height={18}
                  className="bw-rounded-[50%]"
                  alt="img"
                />
              ) : (
                <img
                  src={defaultCoin}
                  width={18}
                  height={18}
                  className="bw-rounded-[50%]"
                  alt="img"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <TabComp
        routes={routes}
        handleShowAllRoutes={handleShowAllRoutes}
        handleRoutesData={handleRoutesData}
        convertVal={convertVal}
        fromChain={fromChain}
        toChain={toChain}
      />
    </div>
  );
}
