import React, { useState, useEffect } from "react";
import { useConnect, useAccount, useSwitchAccount, useConfig } from "wagmi";
import SolanaWallet from "../SolanaWallet";
import useStore from "../../zustand/store";
import BackButton from "../Svg/backButton";
import {
  useDynamicContext,
  useIsLoggedIn,
  useUserWallets,
  useWalletOptions,
} from "@dynamic-labs/sdk-react-core";
import CloseButton from "../Svg/closeButton";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import useWalletDetails from "../../hooks/useWalletDetails";
import ConnectorLoader from "./connectorLoader";
import WalletTab from "./walletTab";
import CustomWallet from "./customWallet";
import { useAccount as useCosmosAccount } from "graz";
import EvmWallet from "../EvmWallet";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import WalletList from "./WalletList";
import styles from "./SelectWallet.module.css";
export default function SelectWallet({
  handleShowWallet,
  connectWalletField,
  portfolio,
  classNames,
  handleRecentSelectedWallet,
}) {
  const config = useConfig();
  const { connectAsync, data, isSuccess } = useConnect({
    config,
  });
  const { isConnected } = useAccount();
  const fromChain = useStore((state) => state.fromChain);
  const toChain = useStore((state) => state.toChain);
  const { primaryWallet } = useDynamicContext();
  const isFullyConnected = useIsLoggedIn();
  const [selectedWallet, setSelectedWallet] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [selectedConnector, setSelectedConnector] = useState();
  const [isSolConnecting, setIsSolanaConnecting] = useState(false);
  const [isTronConnecting, setIsTronConnecting] = useState(false);
  const [isCosmosonnecting, setIsCosmosConnecting] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { connected, address } = useWallet();
  const [selectedWalletTab, setSelectedWalletTab] = useState("All");
  const { data: accounts, isConnected: isCosmosConnected } = useCosmosAccount();
  const { switchAccountAsync } = useSwitchAccount();
  const { open } = useWeb3Modal();
  const configuration = useStore((state) => state.configuration);
  const wallets = useUserWallets();
  const [solCount, setSolCount] = useState(0);
  const { walletOptions } = useWalletOptions();
  const isEvmSupportAvailable = useStore(
    (state) => state.isEvmSupportAvailable
  );
  useEffect(() => {
    let solCount = walletOptions.reduce((acc, item) => {
      if (item.isInstalledOnBrowser) {
        acc++;
      }
      return acc;
    }, 0);
    setSolCount(solCount);
  }, [wallets]);
  async function handleConnect(connector, isWalletConnected) {
    setErrorMsg("");
    let action = isWalletConnected ? switchAccountAsync : connectAsync;
    try {
      if (connector.id !== "walletConnect") {
        let result = await action({ connector });
        if (isWalletConnected) {
          handleShowWallet();
        }
        setErrorMsg((prev) => {
          if (prev?.length && result) {
            handleShowWallet();
          }
          return "";
        });
      } else {
        open({ view: "Connect" });
        handleShowWallet();
      }
    } catch (err) {
      console.log(err, "error");
      setErrorMsg(err.details || err.message || "");
    }
  }
  useEffect(() => {
    let timeout;
    if (data && isSuccess) {
      timeout = setTimeout(() => {
        handleShowWallet();
      }, 1000);
      !portfolio && handleRecentSelectedWallet("evm");
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isSuccess, data]);
  function handleSolConnecting(val, wallet) {
    setErrorMsg("");
    if (wallet) {
      setSelectedWallet(wallet);
    }
    setIsSolanaConnecting(val);
    setShowLoading(false);
  }
  function handleOtherWalletConnecting() {
    setShowLoading(true);
    setIsSolanaConnecting(false);
    setIsTronConnecting(false);
  }
  function handleTronConnection(val, wallet) {
    setErrorMsg("");
    if (wallet) setSelectedWallet(wallet);
    setIsTronConnecting(val);
    setShowLoading(false);
  }
  function handleCosmosConnection(val, wallet) {
    setErrorMsg("");
    if (wallet) setSelectedWallet(wallet);
    setIsCosmosConnecting(val);
    setShowLoading(false);
  }
  useEffect(() => {
    let timeout;
    if (isFullyConnected && primaryWallet !== null && isSolConnecting) {
      timeout = setTimeout(() => {
        handleShowWallet();
        handleSolConnecting(false);
      }, 1000);
      !portfolio && handleRecentSelectedWallet("sol");
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isFullyConnected, primaryWallet, isSolConnecting]);

  useEffect(() => {
    let timeout;
    if (connected && address && isTronConnecting) {
      timeout = setTimeout(() => {
        handleShowWallet();
        handleTronConnection(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [connected, address, isTronConnecting]);
  useEffect(() => {
    let timeout;
    if (isCosmosConnected && accounts?.bech32Address && isCosmosonnecting) {
      timeout = setTimeout(() => {
        handleShowWallet();
        handleCosmosConnection(false);
      }, 1000);
      !portfolio && handleRecentSelectedWallet("cosmos");
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCosmosConnected, accounts?.bech32Address, isCosmosonnecting]);
  function handleWalletTab(wallet) {
    setSelectedWalletTab(wallet);
  }
  return showLoading ? (
    <div className="">
      <div className="bw-flex bw-relative bw-justify-center bw-mb-2">
        <button
          onClick={handleShowWallet}
          className="bw-absolute bw-left-0 bw-top-[25%]"
        >
          <BackButton fill={"var(--tw-text-secondary)"} />
        </button>
        <div className="bw-text-lg bw-font-medium bw-text-text-secondary">
          Connect Wallet
        </div>
        <button
          onClick={handleShowWallet}
          className="bw-absolute bw-right-0 bw-top-[25%]"
        >
          <CloseButton fill={"var(--tw-text-secondary)"} />
        </button>
      </div>
      <WalletTab
        selectedWalletTab={selectedWalletTab}
        handleWalletTab={handleWalletTab}
      />
      <div
        className={`${
          classNames ? classNames : "bw-h-[400px] "
        } bw-w-full bw-relative  bw-overflow-y-auto`}
      >
        <div>
          {((isConnected || primaryWallet?.address || isCosmosConnected) &&
            selectedWalletTab === "All") ||
          (selectedWalletTab === "EVM" && isConnected) ||
          (selectedWalletTab === "Cosmos" && isCosmosConnected) ||
          (selectedWalletTab === "SVM" && wallets?.length) ? (
            <div className="bw-flex bw-items-center bw-gap-x-2 bw-justify-between bw-w-full bw-my-3">
              <p
                style={{ ...configuration?.gradientStyle }}
                className={` bw-bg-clip-text bw-flex bw-items-center bw-gap-x-1 bw-text-sm bw-font-medium bw-text-transparent ${
                  !configuration?.gradientStyle ? styles.gradientbutton : ""
                } `}
              >
                <div
                  style={{ ...configuration?.gradientStyle }}
                  className={`bw-w-[10px] bw-rounded-[50%] ${
                    !configuration?.gradientStyle ? styles.gradientbutton : ""
                  } bw-bg-text-greentext bw-h-[10px]`}
                ></div>{" "}
                Connected
              </p>
              <div className="bw-h-[1px] bw-mr-2 bw-w-full bw-bg-border-primary"></div>
            </div>
          ) : (
            <></>
          )}
          <WalletList
            selectedWalletTab={selectedWalletTab}
            connectWalletField={connectWalletField}
            handleShowWallet={handleShowWallet}
            handleConnect={handleConnect}
            setSelectedConnector={setSelectedConnector}
            setErrorMsg={setErrorMsg}
            setSelectedWallet={setSelectedWallet}
            setShowLoading={setShowLoading}
            portfolio={portfolio}
            fromChain={fromChain}
            toChain={toChain}
            handleSolConnecting={handleSolConnecting}
            handleCosmosConnection={handleCosmosConnection}
            section={"connected"}
          />
        </div>
        <div>
          {selectedWalletTab == "EVM" ||
          selectedWalletTab === "All" ||
          (selectedWalletTab === "Cosmos" &&
            window.keplr &&
            !isCosmosConnected) ||
          (selectedWalletTab === "SVM" && wallets?.length < solCount) ? (
            <div className="bw-flex  bw-items-center bw-gap-x-2 bw-justify-between bw-w-full bw-my-3">
              <p className="bw-text-sm bw-font-medium bw-w-max bw-text-text-secondary  ">
                Installed
              </p>
              <div className="bw-h-[1px] bw-mr-2 bw-w-full bw-bg-border-primary"></div>
            </div>
          ) : (
            <></>
          )}
          <WalletList
            selectedWalletTab={selectedWalletTab}
            connectWalletField={connectWalletField}
            handleShowWallet={handleShowWallet}
            handleConnect={handleConnect}
            setSelectedConnector={setSelectedConnector}
            setErrorMsg={setErrorMsg}
            setSelectedWallet={setSelectedWallet}
            setShowLoading={setShowLoading}
            portfolio={portfolio}
            fromChain={fromChain}
            toChain={toChain}
            handleSolConnecting={handleSolConnecting}
            handleCosmosConnection={handleCosmosConnection}
            section={"installed"}
          />
        </div>
        <div>
          {(selectedWalletTab === "Cosmos" || selectedWalletTab == "All") &
            !window.keplr ||
          (selectedWalletTab === "EVM" && isEvmSupportAvailable) ||
          ((selectedWalletTab === "SVM" || selectedWalletTab == "All") &&
            solCount < 5) ? (
            <div className="bw-flex  bw-items-center bw-gap-x-2 bw-justify-between bw-w-full bw-my-3">
              <p className="bw-text-sm bw-font-medium bw-w-max bw-text-text-secondary  ">
                Supported
              </p>
              <div className="bw-h-[1px] bw-mr-2 bw-w-full bw-bg-border-primary"></div>
            </div>
          ) : (
            <></>
          )}
          <WalletList
            selectedWalletTab={selectedWalletTab}
            connectWalletField={connectWalletField}
            handleShowWallet={handleShowWallet}
            handleConnect={handleConnect}
            setSelectedConnector={setSelectedConnector}
            setErrorMsg={setErrorMsg}
            setSelectedWallet={setSelectedWallet}
            setShowLoading={setShowLoading}
            portfolio={portfolio}
            fromChain={fromChain}
            toChain={toChain}
            handleSolConnecting={handleSolConnecting}
            handleCosmosConnection={handleCosmosConnection}
            section={"supported"}
          />
        </div>
      </div>
      {connectWalletField == "to" ? (
        <CustomWallet
          portfolio={portfolio}
          handleShowWallet={handleShowWallet}
          section={"connected"}
        />
      ) : (
        <></>
      )}
    </div>
  ) : (
    <ConnectorLoader
      selectedWallet={selectedWallet}
      selectedConnector={selectedConnector}
      handleConnect={handleConnect}
      handleOtherWalletConnecting={handleOtherWalletConnecting}
      isSolConnecting={isSolConnecting}
      isTronConnecting={isTronConnecting}
      isCosmosonnecting={isCosmosonnecting}
      isSuccess={isSuccess}
      data={data}
      evmError={errorMsg}
    />
  );
}
