import React from "react";
import SmallValues from "../Tooltip/SmallValues";
import fixString from "../../utils/fixString";
import { FormatCryptoValue } from "../../utils/formatCryptoValue";
function OutputValueComp({ value, decimal }) {
  if (value.length)
    return (
      <FormatCryptoValue value={value} options={{ maxDecimals: decimal }} />
    );
  else return value;
}

export default OutputValueComp;
