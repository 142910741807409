import images from "../../images";
import SideArrow from "../Svg/sideArrow";
import TimeLoaderHistory from "../Animation/TimeLoaderHistory";
import CloseRed from "../Svg/closeRed";
import GradTick from "../Svg/gradTick";
import TxnNav from "../Svg/txnNav";
import ShareIcon from "../Svg/shareicon";
import Star from "../Svg/star";
import styles from "./TxnHistoryComp.module.css";
import useStore from "../../zustand/store";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import fixChar from "../../utils/fixChar";
import OutputValueComp from "../OutputValueComp";
function StatusIcon({ status }) {
  const configuration = useStore((state) => state.configuration);
  return status === "in-progress" ? (
    <TimeLoaderHistory />
  ) : status == "failed" ? (
    <CloseRed />
  ) : status == "success" ? (
    <GradTick
      spinnerColor={configuration?.gradientStyle?.spinnerColor}
      stopColor={configuration?.gradientStyle?.stopColor}
    />
  ) : (
    <></>
  );
}
export default function TxnItem({ array, i, item, chainData, historyData }) {
  let hours = new Date(item?.createdAt).getHours();
  let mins = new Date(item?.createdAt).getMinutes();
  let date = new Date(item?.createdAt).getDate();
  let year = new Date(item?.createdAt).getFullYear();
  let month = new Date(item?.createdAt).getMonth() + 1;
  const { defaultCoin } = images;
  const configuration = useStore((state) => state.configuration);
  const setRoute = useStore((state) => state.setRoute);
  const setTxnDetails = useStore((state) => state.setTxnDetails);
  const { ref, inView } = useInView();
  useEffect(() => {
    inView && historyData.hasNextPage && historyData.fetchNextPage();
  }, [inView]);
  return (
    <div
      onClick={() => {
        setRoute("txnDetails");
        setTxnDetails(item);
      }}
      className="bw-relative bw-cursor-pointer bw-mt-2 bw-flex bw-justify-center bw-w-[96%]"
    >
      <div
        style={{
          boxShadow:
            (configuration &&
              configuration.customTheme &&
              configuration.customTheme?.shadow?.boxShadow) ||
            "1px 1px 10px 3px rgba(0, 0, 0, 0.15)",
        }}
        className="bw-w-[200px] bw-rounded-[50%] bw-absolute bw-mx-auto bw-right-auto bw-z-[-1px] bw-left-auto bw-h-[76px]"
      ></div>
      <div
        ref={array.length - 1 === i ? ref : null}
        className="bw-w-full bw-relative bw-flex-col bw-gap-y-1 bw-z-10 bw-bg-background-container  bw-flex bw-items-center bw-justify-center bw-h-[76px] bw-border-[0.5px] bw-rounded-md bw-mb-4 bw-border-border-primary"
      >
        <div className="bw-flex bw-w-full bw-items-center  bw-gap-x-1 bw-justify-center ">
          <div className="bw-flex-1 bw-flex bw-flex-col bw-items-end">
            <div className="bw-text-text-primary">
              <span className="bw-text-sm bw-font-normal bw-mr-1">
                {item.from.symbol}
              </span>
              <span className="bw-text-lg bw-font-bold">
                <OutputValueComp
                  value={item?.inputAmountDisplay?.toString()}
                  decimal={8}
                />
              </span>
            </div>
            <div className="bw-flex bw-mt-1 bw-items-center bw-gap-x-1">
              <p className="bw-text-xs bw-font-normal bw-text-text-secondary">
                {chainData?.[item.from.chainId]?.name || ""}
              </p>
              {chainData?.[item.from.chainId]?.image ? (
                <img
                  src={chainData[item.from.chainId].image}
                  width={16}
                  height={16}
                  className="bw-rounded-[50%] bw-border bw-bg-background-coin bw-border-border-primary"
                />
              ) : (
                <img
                  src={defaultCoin}
                  width={16}
                  height={16}
                  className="bw-rounded-[50%] bw-border  bw-bg-background-coin bw-border-border-primary"
                />
              )}
            </div>
          </div>
          <div className="bw-flex bw-items-center bw-justify-center bw-flex-[0.8] bw-gap-x-4">
            <div className="bw-flex bw-items-center bw-gap-x-1">
              <SideArrow />
              <SideArrow />
            </div>
            <div className="bw-w-[48px] bw-h-[48px] bw-flex bw-justify-center bw-items-center bw-relative bw-rounded-[50%] bw-border-[0.5px] bw-border-border-primary">
              {item.from.image ? (
                <img
                  src={item.from.image}
                  width={25}
                  height={25}
                  alt="img"
                  className="bw-rounded-[50%] bw-max-w-[25px] bw-max-h-[25px]  bw-object-contain bw-mt-1 bw-bg-background-coin bw-absolute bw-left-[-25%] bw-border bw-border-border-primary"
                />
              ) : (
                <img
                  src={defaultCoin}
                  width={25}
                  height={25}
                  className="bw-rounded-[50%] bw-mt-1 bw-bg-background-coin bw-absolute bw-left-[-25%]  bw-border bw-border-border-primary"
                />
              )}
              <StatusIcon status={item.status} />
              {item.to.image ? (
                <img
                  src={item.to.image}
                  width={25}
                  height={25}
                  alt="img"
                  className="bw-rounded-[50%] bw-max-w-[25px] bw-max-h-[25px]  bw-object-contain bw-mt-1 bw-bg-background-coin bw-absolute bw-right-[-25%] bw-border bw-border-border-primary"
                />
              ) : (
                <img
                  src={defaultCoin}
                  width={25}
                  height={25}
                  className="bw-rounded-[50%] bw-mt-1 bw-bg-background-coin  bw-absolute bw-right-[-25%] bw-border bw-border-border-primary"
                />
              )}
            </div>
            <div className="bw-flex bw-items-center bw-gap-x-1">
              <SideArrow />
              <SideArrow />
            </div>
          </div>
          <div className="bw-flex-1">
            <div className="bw-text-text-primary">
              <span className="bw-text-lg bw-font-bold bw-mr-1">
                <OutputValueComp
                  value={item?.outputAmountDisplay?.toString()}
                  decimal={8}
                />
              </span>
              <span className="bw-text-sm bw-font-normal ">
                {item.to.symbol}
              </span>
            </div>
            <div className="bw-flex bw-mt-1 bw-items-center bw-gap-x-1">
              {chainData?.[item.to.chainId]?.image ? (
                <img
                  src={chainData[item.to.chainId].image}
                  width={16}
                  height={16}
                  className="bw-rounded-[50%] bw-bg-background-coin bw-border bw-border-border-primary"
                />
              ) : (
                <img
                  src={defaultCoin}
                  width={16}
                  height={16}
                  className="bw-rounded-[50%] bw-bg-background-coin bw-border bw-border-border-primary"
                />
              )}
              <p className="bw-text-xs bw-font-normal bw-text-text-secondary">
                {chainData?.[item.to.chainId]?.name || ""}
              </p>
            </div>
          </div>
        </div>
        <div className="bw-absolute  bw-right-[-3%]">
          <TxnNav
            fill={"var(--tw-background-container)"}
            stroke={"var(--tw-border-primary)"}
          />
        </div>
        <div>
          <div className="bw-text-text-primary bw-flex bw-items-end  bw-gap-x-2 bw-text-[10px] bw-font-medium">
            <span className="bw-leading-none bw-mb-[1px]">
              {item?.createdAt
                ? `${hours}:${mins} ${date}-${month}-${year}`
                : ""}
            </span>
            {item.status === "in-progress" ? (
              <p className="bw-text-[10px] bw-underline bw-decoration-dashed bw-underline-offset-2 bw-decoration-text-primary bw-text-text-primary bw-font-medium">
                In Progress
              </p>
            ) : (
              <a
                onClick={(e) => {
                  e.stopPropagation();
                }}
                href={item.finalTxnUrl || ""}
                target="_blank"
              >
                <div className="bw-flex bw-cursor-pointer bw-underline bw-decoration-dashed bw-underline-offset-2 bw-decoration-text-primary bw-items-center bw-gap-x-1">
                  <p className="">Txn Id</p>
                  <div className="bw-w-[10px] bw-h-[10px]">
                    <ShareIcon fill={"var(--tw-text-secondary)"} />
                  </div>
                </div>
              </a>
            )}
          </div>
        </div>
        {item.status === "success" ? (
          item.points ? (
            <div className="bw-bg-background-container bw-absolute bw-right-0 bw-bottom-[-9%] bw-flex bw-items-center bw-gap-x-1  bw-px-1 bw-border bw-border-border-primary bw-rounded-lg">
              <div className="bw-w-[11px] bw-h-[11px]">
                <Star />
              </div>
              <div
                style={{
                  ...configuration.gradientStyle,
                  backgroundClip: "text",
                }}
                className={`bw-text-[10px] ${
                  !configuration?.gradientStyle?.background &&
                  styles.gradienttext
                } bw-font-bold bw-bg-clip-text bw-text-transparent`}
              >
                {item.points} pts
              </div>
            </div>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
