import React from "react";

function DisconnectWallet({ fill }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 490 513"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M341.526 90.7134C335.374 87.0027 331.26 80.2554 331.26 72.5473C331.26 60.8391 340.752 51.3477 352.46 51.3477C355.764 51.3477 358.891 52.1035 361.679 53.4518L361.702 53.4089C368.909 57.3068 375.889 61.55 382.622 66.1165C420.39 91.7311 450.38 127.514 468.972 169.545C490.878 219.07 495.72 274.467 482.738 327.041C469.756 379.615 439.685 426.391 397.245 460.027C354.804 493.663 302.397 512.255 248.247 512.885C194.097 513.515 141.271 496.148 98.0592 463.509C54.8473 430.87 23.6958 384.806 9.49401 332.548C-4.70782 280.291 -1.15612 224.796 19.5917 174.775C37.2003 132.322 66.3492 95.8514 103.51 69.3647C110.135 64.6429 117.015 60.2383 124.129 56.1737L124.198 56.2945C127.287 54.5357 130.862 53.5311 134.671 53.5311C146.379 53.5311 155.871 63.0225 155.871 74.7307C155.871 83.3968 150.671 90.8483 143.221 94.1356C105.294 116.369 75.6343 150.381 58.7748 191.028C41.6272 232.369 38.6918 278.234 50.4293 321.424C62.1668 364.614 87.9128 402.684 123.626 429.659C159.34 456.635 203 470.989 247.753 470.468C292.507 469.947 335.821 454.581 370.897 426.782C405.973 398.982 430.826 360.323 441.555 316.872C452.284 273.42 448.282 227.636 430.177 186.705C412.286 146.258 381.625 112.813 342.915 91.482C342.505 91.2747 342.102 91.0544 341.707 90.8217C341.659 90.7957 341.611 90.7696 341.563 90.7436L341.522 90.7211L341.526 90.7134Z"
        fill={fill}
      />
      <rect
        x="220.477"
        y="0.898438"
        width="49.8478"
        height="282.471"
        rx="24.9239"
        fill={fill}
      />
    </svg>
  );
}

export default DisconnectWallet;
