import images from "../../../images";
import WalletDetails from "../../WalletDetails";
import { useAccount, useNetwork } from "wagmi";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import useStore from "../../../zustand/store";
import Down from "../../Svg/down";
import WalletIcon from "../../Svg/walletIcon";
import useWalletDetails from "../../../hooks/useWalletDetails";
import { useState } from "react";
export default function CoinCard({ chainData, setShowExchangeList, handleShowWallet, setConnectWalletField, coinData, show }) {
  const configuration = useStore((state) => state.configuration);
  const customWallet = useStore((state) => state.customWallet);
  const { isConnected, isConnecting, isReconnecting } = useAccount();
  const solanaConnector = useDynamicContext();
  const { defaultCoin } = images;
  const { isAnyWalletConnected, getWallet } = useWalletDetails({
    chainData,
    show,
  });
  const toDisconnect = useStore((state) => state.toDisconnect);
  const setToDisconnect = useStore((state) => state.setToDisconnect);
  const fromChain = useStore((state) => state.fromChain);
  const toChain = useStore((state) => state.toChain);
  function handleToDisconnect(val) {
    // setToDisconnect(val);
  }
  return (
    <div className="bw-w-[50%] ">
      <p className="bw-text-sm bw-font-medium bw-mb-1 bw-text-text-secondary">{show == "from" ? "Give" : "Get"}</p>
      <div
        style={{
          boxShadow: (configuration && configuration.customTheme && configuration.customTheme?.shadow?.boxShadow) || "1px 1px 7px rgba(0, 0, 0, 0.15)",
        }}
        className="bw-w-full bw-h-[78px] bw-pl-4 md:bw-pl-5 bw-flex bw-items-center bw-gap-x-2 md:bw-gap-x-4 bw-bg-background-card bw-rounded-lg bw-border  bw-border-border-primary"
      >
        <div className={`bw-w-[36px] bw-h-[36px] bw-bg-background-coin bw-flex bw-items-center bw-justify-center bw-rounded-[50%]  bw-relative ${!coinData.image ? " bw-border bw-border-transparent" : "bw-border bw-border-border-primary"}`}>
          {coinData?.image ? <img src={coinData.image} className="bw-rounded-[50%] bw-max-w-[35px] bw-max-h-[35px]  bw-object-contain" alt="img" width={35} height={35} /> : <img src={defaultCoin} className="bw-rounded-[50%] bw-object-fill " alt="img" width={35} height={35} />}
          <div className="bw-w-[21px] bw-h-[21px] bw-border bw-bg-background-coin bw-border-border-primary bw-absolute bw-bottom-[-2px] bw-right-[-5px]  bw-rounded-[50%]">{chainData?.image ? <img className="bw-rounded-[50%]" src={chainData.image} alt="img" /> : <img className="bw-rounded-[50%]  " src={defaultCoin} alt="img" />}</div>
        </div>
        <div
          onClick={() => {
            setShowExchangeList(show);
          }}
          className="bw-cursor-pointer"
        >
          <div className="bw-flex bw-min-w-[78px] bw-min-h-[28px] bw-items-center bw-gap-x-2">
            {coinData?.coin?.length ? <p className="bw-text-xl bw-font-bold bw-text-text-primary">{coinData.coin}</p> : <p className="bw-text-sm bw-text-text-primary bw-font-bold">Select Token</p>}

            <Down fill={coinData?.coin?.length ? "var(--tw-text-primary)" : "var(--tw-text-secondary)"} />
          </div>
          {chainData?.chain?.length ? <p className="bw-text-sm bw-font-normal bw-text-text-secondary">{`on ${chainData.chain}`.length > 12 ? `on ${chainData.chain.substring(0, 12)}..` : `on ${chainData.chain}`}</p> : <p className="bw-text-sm bw-font-bold  bw-text-text-primary">& Chain</p>}
        </div>
      </div>
      <ConnectSection isConnected={show == "to" ? (fromChain?.networkType === toChain?.networkType ? (getWallet(fromChain)?.address?.length || customWallet?.length > 30 ? true : false) : getWallet(toChain)?.address?.length || customWallet?.length > 30 ? true : false) : isAnyWalletConnected} setConnectWalletField={setConnectWalletField} handleShowWallet={handleShowWallet} show={show} chainData={chainData} handleToDisconnect={handleToDisconnect} />
    </div>
  );
}
function ConnectSection({ isConnected, setConnectWalletField, handleShowWallet, show, chainData, handleToDisconnect }) {
  const customWallet = useStore((state) => state.customWallet);
  const fromChain = useStore((state) => state.fromChain);
  const toChain = useStore((state) => state.toChain);
  const { getWallet } = useWalletDetails({
    chainData,
    show,
  });
  return (
    <div className="bw-mt-2 bw-flex bw-justify-end">
      {isConnected ? (
        <WalletDetails
          walletDetails={
            customWallet?.length > 30 && show == "to"
              ? getWallet({})
              : fromChain?.networkType == toChain?.networkType && show == "to"
              ? {
                  disconnect: handleToDisconnect,
                  address: getWallet(fromChain)?.address,
                }
              : getWallet(chainData)
          }
          handleToDisconnect={handleToDisconnect}
          show={show}
          networkType={chainData?.networkType}
          setConnectWalletField={setConnectWalletField}
          handleShowWallet={handleShowWallet}
        />
      ) : (
        <button
          onClick={() => {
            setConnectWalletField(show);
            handleShowWallet();
          }}
          className="bw-mb-1 bw-relative bw-min-w-[100px] bw-flex bw-justify-center bw-items-center bw-gap-x-1 bw-w-[84px] bw-h-[25px]  bw-text-xs bw-font-medium bw-px-2 bw-rounded-xl bw-border bw-border-border-primary bw-bg-background-container bw-text-text-primary"
        >
          <p className="bw-mr-3">Connect</p>
          <div className="bw-absolute bw-right-2">
            <WalletIcon fill={"var(--tw-text-secondary)"} />
          </div>
        </button>
      )}
    </div>
  );
}
