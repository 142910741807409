import { injected, walletConnect } from "wagmi/connectors";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { abstractWalletConnector } from "@abstract-foundation/agw-react/connectors";
import { http, createConfig } from "wagmi";
import config from "./config";
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  bsc,
  zkSync,
  fantom,
  avalanche,
  gnosis,
  linea,
  aurora,
  cronos,
  moonbeam,
  harmonyOne,
  fuse,
  boba,
  okc,
  moonriver,
  polygonZkEvm,
  mode,
  mantle,
  blast,
  scroll,
  celo,
  rootstock,
} from "@wagmi/core/chains";
import { abstract } from "viem/chains";

// Map of chain IDs to predefined wagmi chains
const WAGMI_CHAINS = {
  1: mainnet,
  137: polygon,
  10: optimism,
  42161: arbitrum,
  8453: base,
  56: bsc,
  324: zkSync,
  250: fantom,
  43114: avalanche,
  100: gnosis,
  59144: linea,
  1313161554: aurora,
  25: cronos,
  1284: moonbeam,
  1666600000: harmonyOne,
  122: fuse,
  288: boba,
  66: okc,
  1285: moonriver,
  1101: polygonZkEvm,
  34443: mode,
  5000: mantle,
  81457: blast,
  2741: abstract,
  534352: scroll,
  42220: celo,
  30: rootstock,
};

// Helper function to convert backend chain data to wagmi chain format
const convertToWagmiChain = (chainData) => {
  if (!chainData || chainData.networkType !== "evm") {
    return null;
  }

  // If chain exists in wagmi's predefined chains, use that
  const chainId = Number(chainData.chainId);
  if (WAGMI_CHAINS[chainId]) {
    return WAGMI_CHAINS[chainId];
  }

  // Validate RPC URL
  const rpcUrl = chainData.rpcUrls;
  if (!rpcUrl.length) {
    return null;
  }

  // Create custom chain configuration
  return {
    id: chainId,
    name: chainData.name,
    network: chainData.name.toLowerCase().replace(/\s+/g, ""),
    image: chainData.image,
    nativeCurrency: {
      decimals: 18,
      name: chainData.nativeCurrency?.name || "Ethereum",
      symbol: chainData.nativeCurrency?.symbol || "ETH",
    },
    rpcUrls: {
      default: {
        http: [...rpcUrl],
      },
      public: {
        http: [...rpcUrl],
      },
    },
    blockExplorers: chainData?.explorer
      ? {
          default: {
            name: chainData.name,
            url: chainData.explorer?.website,
          },
        }
      : undefined,
    testnet: chainData.isTestnet || false,
  };
};

const projectId = "a3cc5b84df95db911e2f9f9655114425";

// For development only - use these hardcoded chains if API is failing
// Comment this out when API is working properly
const DEV_CHAINS = [mainnet, polygon, optimism, arbitrum, base];

// Fetch chains synchronously (not ideal, but necessary for our use case)
let allChains = [mainnet]; // Start with mainnet as fallback
let chainsTransports = { [mainnet.id]: http() };
function getWagmiConfig(chains) {
  try {
    const chainsData = chains;

    // Determine the structure of the response
    if (Array.isArray(chainsData.data)) {
    } else if (typeof chainsData === "object") {
    }

    // Extract chains depending on the structure
    const chainsToProcess = Array.isArray(chainsData.data)
      ? chainsData.data
      : Object.values(chainsData);

    // Filter and convert EVM chains
    const apiChains = chainsToProcess
      .filter((chain) => {
        const isEvm = chain.networkType === "evm";

        return isEvm;
      })
      .map(convertToWagmiChain)
      .filter(Boolean);

    if (apiChains.length > 0) {
      // Log all chains we found

      allChains = apiChains;

      // Create transports object dynamically
      chainsTransports = allChains.reduce((acc, chain) => {
        // Use the chain's RPC URL from the chain configuration
        const rpcUrl = chain.rpcUrls.default.http[0];
        acc[chain.id] = http(rpcUrl);
        return acc;
      }, {});
    } else {
      // For development - uncomment to use hardcoded chains when API fails
      // allChains = DEV_CHAINS;
      // chainsTransports = DEV_CHAINS.reduce((acc, chain) => {
      //   acc[chain.id] = http();
      //   return acc;
      // }, {});
      // console.log("Using development fallback chains");
    }
  } catch (parseError) {
    console.log(parseError, "parseError");
  }

  // Log final chains and config

  // Now create the wagmi config with all chains
  const wagmiConfig = defaultWagmiConfig({
    chains: allChains,
    projectId,
    enableWalletConnect: true,
    enableInjected: false,
    enableEIP6963: false,
    enableCoinbase: false,
  });

  // Log the chains in the wagmi config

  // Create the final config with all chains
  const finalConfig = createConfig({
    multiInjectedProviderDiscovery: true,
    chains: allChains,
    transports: chainsTransports,
    ...wagmiConfig,
    connectors: [
      injected(),
      walletConnect({
        projectId,
        showQrModal: true,
        qrModalOptions: {
          themeMode: "light",
        },
      }),
      abstractWalletConnector(),
    ],
  });

  // Log the chains in the final config

  // Create web3Modal with all chains
  createWeb3Modal({
    wagmiConfig: finalConfig,
    projectId,
    defaultChain: allChains[0],
    themeMode: "light",
    includeWalletIds: [
      "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    ],
  });
  return finalConfig;
}
// Export the config with all chains
export default getWagmiConfig;
