import styles from "./SelectWallet.module.css";
import EvmWallet from "../EvmWallet";
import SolanaWallet from "../SolanaWallet";
import CosmosWallet from "../CosmosWallet";

export default function WalletList({
  selectedWalletTab,
  connectWalletField,
  handleShowWallet,
  handleConnect,
  setSelectedConnector,
  setSelectedWallet,
  setShowLoading,
  portfolio,
  fromChain,
  toChain,
  handleSolConnecting,
  handleCosmosConnection,
  setErrorMsg,
  section,
}) {
  return (
    <div
      className={` bw-mx-auto bw-px-2  bw-w-full bw-flex bw-justify-between bw-flex-wrap `}
    >
      <EvmWallet
        section={section}
        selectedWalletTab={selectedWalletTab}
        styles={styles}
        connectWalletField={connectWalletField}
        handleShowWallet={handleShowWallet}
        setSelectedConnector={setSelectedConnector}
        setSelectedWallet={setSelectedWallet}
        handleConnect={handleConnect}
        setShowLoading={setShowLoading}
      />

      {selectedWalletTab == "All" || selectedWalletTab == "SVM" ? (
        <SolanaWallet
          section={section}
          handleShowWallet={handleShowWallet}
          fromChain={fromChain}
          toChain={toChain}
          connectWalletField={connectWalletField}
          handleSolConnecting={handleSolConnecting}
          portfolio={portfolio}
          selectedWalletTab={selectedWalletTab}
        />
      ) : (
        <></>
      )}
      {selectedWalletTab == "All" || selectedWalletTab == "Cosmos" ? (
        <CosmosWallet
          selectedWalletTab={selectedWalletTab}
          handleCosmosConnection={handleCosmosConnection}
          setErrorMsg={setErrorMsg}
          connectWalletField={connectWalletField}
          handleShowWallet={handleShowWallet}
          section={section}
        />
      ) : (
        <></>
      )}
      {/* {selectedWalletTab == "All" || selectedWalletTab == "Other" ? (
          <TronWallet
            handleTronConnection={handleTronConnection}
            connectWalletField={connectWalletField}
            fromChain={fromChain}
            toChain={toChain}
            selectedWalletTab={selectedWalletTab}
            handleShowWallet={handleShowWallet}
          />
        ) : (
          <></>
        )} */}
    </div>
  );
}
