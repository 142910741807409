import useStore from "../../zustand/store";
import styles from "./CosmosWallet.module.css";
import images from "../../images";
import {
  useAccount,
  useConnect,
  useDisconnect,
  WalletType,
  useActiveWalletType,
  useActiveChainIds,
  useSuggestChainAndConnect,
  useSuggestChain,
  getWallet,
} from "graz";
import InstalledIcon from "../InstalledIcon";
import getChainIds from "../../utils/getChainIds";
import useWalletDetails from "../../hooks/useWalletDetails";
import DisconnectWallet from "../Svg/disconnectWallet";
import SupportedWallets from "../SelectWallet/SupportedWallets";
export default function CosmosWallet({
  selectedWalletTab,
  handleCosmosConnection,
  setErrorMsg,
  connectWalletField,
  handleShowWallet,
  section,
}) {
  const configuration = useStore((state) => state.configuration);
  const fromChain = useStore((state) => state.fromChain);
  const toChain = useStore((state) => state.toChain);
  const setCosmosChainIds = useStore((state) => state.setCosmosChainIds);
  const chainIds = useActiveChainIds();
  const { refetch } = useWalletDetails({});
  const { connect, connectAsync } = useConnect({
    onError: (err) => setErrorMsg(err.message),
  });
  const { disconnect } = useDisconnect();
  const { data: accounts, isConnected, reconnect } = useAccount();
  const setToDisconnect = useStore((state) => state.setToDisconnect);
  const { keplr, ethereumicon, disconnecticon, cosmosicon } = images;
  const walletType = useActiveWalletType();
  const { suggestAsync } = useSuggestChain();
  const { suggestAndConnectAsync } = useSuggestChainAndConnect();
  const walletIcons = {
    keplr,
  };
  async function connectWallet() {
    try {
      let chainIds = await getChainIds();
      if (
        !chainIds.includes(fromChain?.chainId) &&
        fromChain?.networkType == "cosmos"
      ) {
        let chainInfo = {
          ...fromChain?.chainInfo,
        };

        await suggestAndConnectAsync({
          chainInfo: chainInfo,
          walletType: WalletType.KEPLR,
        });
        let chainIds1 = await getChainIds();
        setCosmosChainIds(chainIds1);
        await connectAsync({
          chainId: chainIds1,
          walletType: WalletType.KEPLR,
        });
        await refetch();
      } else {
        connect({
          chainId: chainIds,
          walletType: WalletType.KEPLR,
        });
      }

      handleCosmosConnection(true, "keplr");
    } catch (error) {
      console.error(error.message);
    }
  }
  let isConnectedCosmos = accounts?.bech32Address?.length && isConnected;
  return (section == "connected" && isConnectedCosmos) ||
    (section == "installed" && !isConnectedCosmos && window.keplr) ? (
    <div
      style={
        true
          ? {
              ...configuration?.gradientStyle,
            }
          : {}
      }
      className={`bw-w-[47%] bw-relative bw-mb-4 ${
        selectedWalletTab === "Cosmos" ? "" : "  "
      } bw-rounded-md bw-p-[1px] ${
        !configuration.gradientStyle && isConnected
          ? styles.gradientbutton
          : "bw-bg-border-primary"
      } `}
      onClick={() => {
        if (connectWalletField == "to" && isConnected) {
          setToDisconnect(false);
          handleShowWallet();
        } else if (isConnected) disconnect();
        else {
          connectWallet();
        }
      }}
    >
      <div
        className={` bw-relative bw-flex bw-w-full bw-bg-background-container  hover:bw-shadow-sm bw-font-medium  hover:bw-font-bold hover:bw-text-text-primary  bw-items-center bw-gap-x-3  bw-rounded-md  bw-h-[56px] bw-px-3 bw-cursor-pointer  ${
          ""
          // isFullyConnected ? "bw-pointer-events-none bw-opacity-50" : ""
        }${
          accounts?.bech32Address?.length && isConnected
            ? " bw-text-text-primary"
            : "bw-text-text-secondary"
        }`}
      >
        <img
          src={walletIcons["keplr"]}
          width={32}
          height={32}
          className=""
          alt="img"
        />
        <p className="bw-text-xs bw-w-max ">Keplr</p>
        {/* {accounts?.bech32Address?.length && isConnected ? (
          <div className="bw-text-[10px] bw-flex bw-items-center bw-gap-x-1 bw-mt-[-2px] bw-font-medium bw-absolute bw-top-1 bw-right-2">
            <div
              style={{ ...configuration?.gradientStyle }}
              className={`bw-w-[10px] bw-rounded-[50%] ${
                !configuration?.gradientStyle ? styles.gradientbutton : ""
              } bw-bg-text-greentext bw-h-[10px]`}
            ></div>{" "}
            <p
              style={{ ...configuration?.gradientStyle }}
              className={` bw-bg-clip-text bw-text-transparent ${
                !configuration?.gradientStyle ? styles.gradientbutton : ""
              } `}
            >
              Connected
            </p>
          </div>
        ) : window?.keplr ? (
          <div className="bw-absolute bw-top-[2px] bw-right-1">
            <p className="bw-text-text-secondary bw-text-xs bw-font-normal">
              Installed
            </p>
          </div>
        ) : (
          <></>
        )} */}
      </div>
      <div
        style={
          true
            ? {
                ...configuration?.gradientStyle,
              }
            : {}
        }
        className={` bw-absolute bw-right-2 bw-bottom-[-50%] bw-mb-4 ${
          selectedWalletTab === "Cosmos" ? "" : "  "
        } bw-rounded-md bw-p-[1px] ${
          !configuration.gradientStyle && isConnected
            ? styles.gradientbutton
            : "bw-bg-border-primary"
        } `}
      >
        <div className=" bw-w-[81px] bw-h-[25px]  bw-rounded-md bw-flex bw-bg-background-container bw-justify-center bw-items-center bw-gap-x-1  ">
          <img src={cosmosicon} width="21" height="21" alt="img" />
          <p className="bw-text-xs bw-font-bold bw-text-text-primary">COSMOS</p>
        </div>
      </div>

      {isConnected ? (
        <p
          onClick={() => disconnect()}
          className=" bw-cursor-pointer bw-absolute bw-right-[5px] bw-top-1 bw-text-[10px] bw-text-text-primary  bw-z-40 bw-rounded-[50%]"
        >
          <div
            style={{
              boxShadow:
                (configuration &&
                  configuration.customTheme &&
                  configuration.customTheme?.shadow?.boxShadow) ||
                "1px 1px 7px rgba(0, 0, 0, 0.20)",
            }}
            className="bw-w-[15px] bw-h-[15px] bw-rounded-[50%] "
          >
            <DisconnectWallet fill={"var(--tw-text-primary)"} />
          </div>
        </p>
      ) : (
        <></>
      )}
    </div>
  ) : !window.keplr && section == "supported" ? (
    <SupportedWallets wallet={"keplr"} chain={"cosmos"} />
  ) : (
    <></>
  );
}
