import useStore from "../../zustand/store";
import inIframe from "../../utils/inIframe";
import Routes from "../Routes";
import Navbar from "../Navbar";
import getParams from "../../utils/getParams";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import { useReconnect, useAccount, useConnectors } from "wagmi";
import { useQuery } from "@tanstack/react-query";
import controllers from "../../Actions/Controllers";
export default function WidgetContainer() {
  const configuration = useStore((state) => state.configuration);
  const setFromChain = useStore((state) => state.setFromChain);
  const setFromCoin = useStore((state) => state.setFromCoin);
  const setToCoin = useStore((state) => state.setToCoin);
  const setToChain = useStore((state) => state.setToChain);
  const setChainObj = useStore((state) => state.setChainObj);
  const { width, height } = getParams();
  const userId = useStore((state) => state.userId);
  const setUserId = useStore((state) => state.setUserId);
  const { reconnectAsync } = useReconnect();
  const connectors = useConnectors();
  const setTokens = useStore((state) => state.setTokens);
  const { addresses, isConnecting, isReconnecting } = useAccount();
  const searchCustomCoin = useStore((state) => state.searchCustomCoin);
  const theme = useStore((state) => state.theme);
  const fetchChains = useStore((state) => state.fetchChains);

  useEffect(() => {
    const uid = uuidv4();
    if (userId == null) setUserId(uid);
    else return;
  }, []);
  async function callReconnect() {
    try {
      let res = await reconnectAsync(connectors[0]);
    } catch (err) {
      console.log(err, "reconnecterror");
    }
  }
  useEffect(() => {
    setTimeout(() => {
      callReconnect();
    }, 1000);
  }, []);
  useEffect(() => {
    if (theme == "dark") {
      let container = document.querySelector(".lazy_theme");
      if (container) {
        container.setAttribute("data-theme", "dark");
      }
    } else {
      let container = document.querySelector(".lazy_theme");
      if (container) {
        container.setAttribute("data-theme", "light");
      }
    }
  }, [theme]);
  const fetchTokens = useQuery({
    queryKey: ["tokens", searchCustomCoin.chainId, searchCustomCoin.address],
    queryFn: async function () {
      let res = await controllers.fetchTokens(
        searchCustomCoin.chainId,
        searchCustomCoin.address
      );
      return await res.json();
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    if (
      fetchTokens.isSuccess ||
      fetchTokens.isRefetching ||
      fetchTokens.isLoading
    )
      setTokens(fetchTokens);
  }, [fetchTokens.isSuccess, fetchTokens.isRefetching, fetchTokens.isLoading]);
  function fetchTokens1(item, setCoin, config) {
    controllers
      .fetchTokens(item.chainId, config)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        res.data?.[item.chainId]?.forEach((item) => {
          setCoin({
            coin: item.symbol,
            ...item,
            availBal: "",
          });
        });
      });
  }
  useEffect(() => {
    if (fetchChains.data) {
      let datas = fetchChains.data;
      let chainObj = {};
      datas?.data?.forEach((item) => {
        chainObj[item.chainId] = item;
        if (
          // !fromChain?.chain.length &&
          item.chainId === (configuration?.defaultChains?.from?.chainId || "10")
        ) {
          setFromChain({ chain: item.name, ...item });
          fetchTokens1(
            item,
            setFromCoin,
            configuration?.defaultTokens?.from?.tokenAddress ||
              "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
          );
        }
        if (
          // !toChain?.chain.length &&
          item.chainId === (configuration?.defaultChains?.to?.chainId || "sol")
        ) {
          setToChain({ chain: item.name, ...item });
          fetchTokens1(
            item,
            setToCoin,
            configuration?.defaultTokens?.to?.tokenAddress ||
              "So11111111111111111111111111111111111111112"
          );
        }
      });
      setChainObj(chainObj);
    }
  }, [
    fetchChains.data,
    configuration.defaultTokens,
    configuration.defaultChains,
  ]);
  return (
    <div
      style={{
        width: inIframe() ? "443px" : "443px",
        minHeight: inIframe ? "400px" : "400px",
        borderRadius: "16px",
        padding: "10px",
        paddingTop: "15px",
        paddingBottom: "20px",
        fontFamily: configuration?.customTheme?.fontFamily || "lato",
        boxShadow:
          (configuration &&
            configuration.customTheme &&
            configuration.customTheme?.shadow?.boxShadow) ||
          "1px 1px 7px rgba(0, 0, 0, 0.15)",
        marginTop: inIframe() ? "0px" : "20px",
        marginBottom: inIframe() ? "0px" : "70px",
        ...configuration.containerStyle,
      }}
      className={`${
        inIframe() ? "" : "md:bw-w-[443px] md:bw-min-h-[400px] bw-max-w-[443px]"
      } bw-bg-background-container bw-border bw-border-border-primary  bw-w-full lazy_theme md:bw-mx-auto bw-relative bw-mx-auto blockend-container   ${
        configuration.classNames
      } `}
    >
      <Routes fetchChains={fetchChains} />
      <Navbar />
    </div>
  );
}
