import React, { useEffect, useState, useContext, useRef } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import controllers from "../../Actions/Controllers";
import useStore from "../../zustand/store";
import { isEmpty, padEnd } from "lodash";
import { useSendTransaction, usePrepareTransactionRequest, useAccount, useSwitchChain, useSignTypedData, useSignMessage, useWalletClient } from "wagmi";
import { parseEther } from "viem";
import SuccessCard from "./SuccessCard";
import useSolanaTxn from "../../hooks/metamask/useSolanaTxn";
import useCosmosTxn from "../../hooks/useCosmosTxn";
import useTronTxn from "../../hooks/useTronTxn";
import { TxnSuccessContext } from "../Context/txnSuccessContext";
import { TxnErrorContext } from "../Context/txnErrorContext";
import TxnExeButton from "./TxnExeButton";
import TransactionFailedCTA from "./TransactionFailedCTA";
import TxnStatusComp from "./TxnStatusComp";
import TransactionLinkComp from "./TransactionLinkComp";
import Steps from "./steps";
import StepsIcons from "./stepsIcons";
import TxnLoad from "../Animation/TxnLoad";
import UpdateQuote from "./UpdateQuote";
import useRegisterError from "../../hooks/useRegisterError";
import validError from "../../utils/validError";
import TransactionUpdateComp from "./TransactionUpdateComp";
import HomeButton from "./HomeButton";
import CreateTxComp from "./CreateTxComp";
import { utils, ethers, Wallet } from "ethers";
let interval;
let count = 0;
function TransactionComp({ setExchange, amount, startTimer, handleBack, details }) {
  //zustand
  const persist = useStore((state) => state.persist);
  const setPersist = useStore((state) => state.setPersist);
  const isMounted = useRef(false);
  //states
  const [allSteps, setAllSteps] = useState({ steps: null, currentStep: 0 });
  const [stepData, setStepData] = useState({});
  const [disableButton, setDisableButton] = useState(true);
  const [errorMessage, setErrorMessage] = useState({});
  const [isTryAgain, setIsTryAgain] = useState(false);
  const [isTerminalStatus, setIsTerminalStatus] = useState(false);
  //context
  const { handleTxnSuccess } = useContext(TxnSuccessContext);
  const { isErrorMessage, handleErrorMessage } = useContext(TxnErrorContext);
  const { chain, connector } = useAccount();
  const isPolling = useRef(false);
  const { data: walletClient } = useWalletClient();
  // const prepare = usePrepareTransactionRequest({
  //   to: useStore.getState()?.persist?.txnEvm?.to,
  //   value: useStore.getState()?.persist?.txnEvm?.value || "0",
  // });
  const isHistory = persist.isHistory;
  //txn hooks
  const { data, isError, error, sendTransaction, reset } = useSendTransaction();
  const { signTypedDataAsync, error: signError, isError: signErrorIsError } = useSignTypedData();
  const { signMessageAsync, error: signMessageError, isError: signMessageIsError } = useSignMessage();
  const { registerError } = useRegisterError();
  const { switchChain } = useSwitchChain({
    chainId: Number(persist?.stepData?.from?.chainId || "0"),
    throwForSwitchChainNotSupported: true,
    onError(error) {
      console.log("Error", error);
      let isValidError = validError(error.message);
      if (isValidError) registerError({ module: "wallet-error-switch", error: error.message });
    },
  });
  const isHistoryData = !isEmpty(useStore.getState()?.persist?.historyData);
  const isevmTxnError = isError || signErrorIsError;
  const { solData, solError, solErrMessage, handleTxn, resetSolData } = useSolanaTxn();
  const { cosmosData, cosmosError, cosmosErrorMessgae, handleCosmosTransaction, resetCosmosData } = useCosmosTxn();
  const { tronData, tronError, handleTronTxn, resetTronData } = useTronTxn();
  let txnTextObj = persist?.allSteps?.steps?.[allSteps?.currentStep || persist?.allSteps?.currentStep]?.texts;
  let txnProcessType = disableButton ? (persist.hash ? "process" : "loading") : "pre";

  let adapterError = error?.message || signError?.details || solErrMessage?.message || cosmosErrorMessgae?.message || tronError?.message || "";
  let errorText = errorMessage?.error?.length ? errorMessage.error : error?.message?.length ? error.message : adapterError?.length ? adapterError : "";
  // history logics
  useEffect(() => {
    if (persist?.allSteps && isHistory) {
      setAllSteps(persist.allSteps);
    }
  }, [persist.allSteps]);
  useEffect(() => {
    if (isHistoryData && typeof persist?.historyData?.currentStepIndex !== "undefined" && persist?.historyData?.currentStepStatus == "not-started") {
      callNextTx(persist?.stepData, persist?.routeId);
    } else if (persist?.historyData?.currentStepStatus == "in-progress") {
      setDisableButton(true);
      startTimer(true);
    }
  }, [isHistoryData]);
  //  history logics end
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  const fetchStatus = useMutation({
    mutationKey: "status",
    mutationFn: async ({ routeId, stepId, txnHash }) => {
      let res = await controllers.fetchStatus(routeId, stepId, txnHash);
      return res.json();
    },

    refetchOnWindowFocus: false,
    refetchOnMount: false,

    onError: (err) => {
      let error = err.details || err.message || "";
      setErrorMessage({ error });
      // callRegisterError("/submitTxn", { error });
    },
  });
  function callRegisterError(module, data) {
    registerError({
      module,
      routeId: persist?.routeId || "",
      stepId: persist?.stepData?.stepId || "",
      error: data.error,
      userWalletAddress: persist?.route?.userWalletAddress,
    });
  }
  const nextTx = useMutation({
    mutationKey: "nexttx",
    mutationFn: async ({ id, routeId }) => {
      let res = await controllers.fetchNextTx(id, routeId);
      return await res.json();
    },

    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 1000,
    onSuccess: (data, variables) => {
      if (isMounted.current) {
        if (data.status == "error") {
          setErrorMessage(data);
          // callRegisterError("/nextTx", data);
        } else {
          setErrorMessage({ error: "" });
        }
        handleSkipTxn(data);
        setDisableButton(false);
        setPersist({
          ...useStore.getState()?.persist,
          loading: false,
          txnEvm: data?.data?.txnData?.txnEvm,
          nextTx: data,
          hash: null,
          txnType: data?.data?.txnData?.txnType || null,
          gasless: data?.data?.txnData?.gasless,
        });
      }
      // else {
      //   if (persist?.allSteps?.currentStep === 0) {
      //     setPersist({
      //       ...useStore.getState().persist,
      //       isQuoteTimer: false,
      //       quoteStartTime: new Date().getTime(),
      //     });
      //   }
      // }
    },
    onError: (err) => {
      let error = err.details || err.message || "";
      setErrorMessage({ error });
      // callRegisterError("/nextTx", { error });
    },
  });
  const submitSignedTxn = useMutation({
    mutationKey: "submitSignedData",
    mutationFn: async ({ routeId, stepId, signedTxn }) => {
      let res = await controllers.submitSignedTxn(routeId, stepId, signedTxn);
      return res.json();
    },
  });
  useEffect(() => {
    if (persist?.allSteps?.currentStep === 0 && nextTx.data) {
      let data = nextTx.data;
      setPersist({
        ...useStore.getState().persist,
        isQuoteTimer: data.status == "error" || persist?.isTryAgain ? false : true,
        quote: data.status == "error" || persist?.isTryAgain ? "disable" : "enable",
        quoteStartTime: new Date().getTime(),
      });
    } else {
      setPersist({
        ...useStore.getState().persist,
        isQuoteTimer: false,
        quote: "disable",
      });
    }
  }, [persist?.allSteps?.steps, nextTx.data]);

  const txnBody = useQuery({
    queryKey: ["txnbody", persist?.routeId],
    queryFn: async () => {
      let res = await controllers.fetchTxnBody(`/createTx?routeId=${persist?.routeId || ""}`);
      return await res.json();
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: persist.routeId?.length && !persist.nextTx && !Boolean(isHistory) ? true : false,
  });
  useEffect(() => {
    if (txnBody.data) {
      let data = txnBody.data;
      if (data.status == "error") {
        setErrorMessage(data);
        // callRegisterError("/createTx", data);
      } else {
        setErrorMessage({ error: "" });
      }
      if (typeof data?.data !== "undefined" && !isHistoryData) {
        callNextTx(data?.data?.steps[0], data?.data?.routeId);
        setTimeout(() => {
          setStepData(data?.data?.steps[0]);
          setAllSteps({
            steps: data?.data?.steps || persist?.allSteps?.steps || [],
            currentStep: 0,
            type: "preText",
          });
        }, 1200);
        if (data?.data?.status == "error") {
          setErrorMessage({ ...data.data });
        }
      }
    }
  }, [txnBody.data]);
  useEffect(() => {
    if (txnBody.error) {
      let err = txnBody.error;
      let error = err.details || err.message || "";
      setErrorMessage({ error });
      // callRegisterError("/createTx", { error });
    }
  }, [txnBody.error]);
  useEffect(() => {
    let walletError = error?.message?.length || solErrMessage?.message?.length || cosmosErrorMessgae?.message?.length || tronError?.message?.length;
    let walletErrorText = error?.message || solErrMessage?.message || cosmosErrorMessgae?.message || tronError?.message || "";
    let isValidError = validError(walletErrorText);
    if (walletError && isValidError) {
      callRegisterError("/wallet error", {
        error: walletErrorText,
      });
    }
  }, [error?.message, solErrMessage?.message, cosmosErrorMessgae?.message, tronError?.message]);

  useEffect(() => {
    if ((!fetchStatus.isFetching || persist.statusData?.status == "success") && allSteps.currentStep === allSteps.steps?.length && (txnBody.isSuccess || persist.isSuccess)) {
      handleTxnSuccess(true);
    } else {
      handleTxnSuccess(false);
    }
  }, [fetchStatus.isFetching, fetchStatus.data, allSteps, txnBody.isSuccess, persist.isSuccess, persist?.statusData]);

  useEffect(() => {
    if (isevmTxnError || solError || cosmosError || tronError?.length || errorMessage?.error?.length || persist.statusData?.status == "failed" || persist.statusData?.status == "partial-success") {
      handleErrorMessage(true);
    } else {
      handleErrorMessage(false);
    }
  }, [isevmTxnError, solError, cosmosError, tronError?.length, errorMessage, fetchStatus?.data, persist?.statusData]);
  useEffect(() => {
    if (isErrorMessage) reset();
  }, []);

  useEffect(() => {
    if ((!isevmTxnError || !solError || !cosmosError || !tronError?.length || !errorMessage?.error?.length) && (persist?.hash || data || solData?.signature || cosmosData?.signature || tronData?.txid) && fetchStatus?.data?.data?.srcTxnHash !== (data || solData?.signature || cosmosData?.signature || tronData?.txid || persist?.hash)) {
      setAllSteps((prev) => {
        return { ...prev, type: "postText" };
      });
    }
  }, [isevmTxnError, solError, cosmosError, tronError, data, solData, cosmosData, tronData, errorMessage?.error, persist?.hash]);
  useEffect(() => {
    let tempPersist = { ...useStore.getState()?.persist };
    if (allSteps.steps !== null) {
      tempPersist = {
        ...tempPersist,
        allSteps,
        stepStatus: handleStepStatus(),
      };
    }
    if (data || solData?.signature || cosmosData?.signature || tronData?.txid) {
      tempPersist = {
        ...tempPersist,
        hash: data || solData?.signature || cosmosData?.signature || tronData?.txid,
      };
    }
    if (!isEmpty(stepData)) {
      tempPersist = { ...tempPersist, stepData };
    }

    persist?.routeId && setPersist({ ...tempPersist, isSuccess: true });
  }, [allSteps, data, solData, cosmosData, tronData, stepData]);
  useEffect(() => {
    if (!isEmpty(persist?.allSteps)) {
      if (data || solData?.signature || cosmosData?.signature || tronData?.txid || persist?.hash) {
        setAllSteps({ ...persist?.allSteps });
      } else {
        setAllSteps({ ...persist?.allSteps, type: "preText" });
      }
    }
    if (typeof persist?.loading !== "undefined") {
      if (data || solData?.signature || cosmosData?.signature || tronData?.txid || persist?.hash) {
        console.log(persist?.loading, "persist?.loading");
        setDisableButton(persist?.loading);
      } else {
        setDisableButton(false);
      }
    }
    if (persist?.routeId) {
      !isHistory && setExchange();
    }
    handleSkipTxn(persist?.nextTx);
    if (persist?.nextTx?.status === "error") {
      setDisableButton(true);
      callNextTx(persist?.stepData, persist?.routeId);
    }
  }, []);
  useEffect(() => {
    if (solData?.signature) {
      setAllSteps({ ...persist?.allSteps });
      setDisableButton(true);
    }
  }, [solData?.signature]);
  async function callStatus() {
    if (isPolling.current) {
      return;
    }
    const currentHash = persist?.hash;

    // Reset terminal status if we detect a new transaction hash
    if (persist?.prevHash && persist.prevHash !== currentHash) {
      setIsTerminalStatus(false);
      setPersist({
        ...useStore.getState().persist,
        prevHash: currentHash,
      });
    } else if (!persist?.prevHash && currentHash) {
      setPersist({
        ...useStore.getState().persist,
        prevHash: currentHash,
      });
    }

    if (isTerminalStatus) {
      clearInterval(interval);
      return;
    }

    try {
      isPolling.current = true;
      const response = await fetchStatus.mutateAsync({
        routeId: persist?.routeId || txnBody.data?.data?.routeId,
        stepId: stepData?.stepId || persist?.stepData?.stepId,
        txnHash: currentHash,
      });

      // Check for terminal status before handling the response
      if (response?.data?.status === "success" || response?.data?.status === "failed" || response?.data?.status === "partial-success") {
        setIsTerminalStatus(true);
        clearInterval(interval);
      }

      handleStatus(response);
    } catch (err) {
      console.log(err);
      // clearInterval(interval);
    } finally {
      isPolling.current = false;
    }
  }
  useEffect(() => {
    const currentHash = persist?.hash;

    // Reset terminal status if we detect a new transaction hash
    if (persist?.prevHash && persist.prevHash !== currentHash) {
      setIsTerminalStatus(false);
    }

    if (currentHash && allSteps.currentStep <= allSteps?.steps?.length - 1 && !isTerminalStatus) {
      // Initial call
      callStatus();
      interval = setInterval(() => {
        callStatus();
      }, 2000);
    } else {
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [allSteps, persist?.hash, isTerminalStatus, persist?.prevHash]);

  // Reset terminal status when trying again
  useEffect(() => {
    if (isTryAgain) {
      setIsTerminalStatus(false);
      setPersist({
        ...useStore.getState().persist,
        prevHash: null,
      });
    }
  }, [isTryAgain]);

  useEffect(() => {
    if (isErrorMessage && !isTryAgain) {
      setDisableButton(false);
      setPersist({ ...useStore.getState()?.persist, loading: false });
    } else {
      setPersist({ ...useStore.getState()?.persist, loading: true });
    }
  }, [isErrorMessage, isTryAgain]);
  function handleStepStatus(status) {
    let stepStatus = [...(useStore.getState()?.persist?.stepStatus || [])];
    if (stepStatus.length) {
      stepStatus[allSteps?.currentStep] = {
        ...stepStatus[allSteps?.currentStep],
        type: allSteps?.type,
      };
    } else {
      stepStatus[0] = {
        ...stepStatus[allSteps?.currentStep],
        type: allSteps?.type,
      };
    }
    if (status) {
      stepStatus[allSteps?.currentStep] = {
        ...stepStatus[allSteps?.currentStep],
        ...status,
      };
    }
    return stepStatus;
  }
  function handleTxnObj(data) {
    let persistedTxnHashObj = useStore?.getState()?.persist?.txnHashObj || {};
    let tempTxnObj = { ...persistedTxnHashObj };
    if (!isEmpty(tempTxnObj[data?.data?.stepId])) {
      tempTxnObj[data?.data?.stepId] = {
        ...tempTxnObj[data?.data?.stepId],
        destTxnHash: data?.data?.destTxnHash || data?.destTxnHash || "",
        destTxnUrl: data?.data?.destTxnUrl || data?.destTxnUrl || "",
        srcTxnUrl: data?.data?.srcTxnUrl || data?.srcTxnUrl || "",
        srcTxnHash: data?.data?.srcTxnHash || data?.srcTxnHash || "",
      };
    } else {
      tempTxnObj[data?.data?.stepId] = {
        srcTxnUrl: data?.data?.srcTxnUrl || "",
        stepId: data?.data?.stepId || "",
        srcTxnHash: data?.data?.srcTxnHash || "",
        destTxnUrl: data?.data?.destTxnUrl || "",
        destTxnHash: data?.data?.destTxnHash || "",
      };
    }
    setPersist({
      ...useStore?.getState()?.persist,
      txnHashObj: tempTxnObj,
    });
  }
  function setNextStep() {
    setAllSteps({
      ...allSteps,
      steps: txnBody?.data?.data?.steps || persist?.allSteps?.steps || [],
      currentStep: allSteps.currentStep + 1,
      type: "preText",
    });
  }
  function handleStatus(data) {
    if (data?.data?.status) {
      setPersist({
        ...useStore?.getState()?.persist,
        statusData: data?.data,
        stepStatus: handleStepStatus({
          status: data?.data?.status == "in-progress" ? "pending" : data?.data?.status,
        }),
      });
    }
    handleTxnObj(data);
    if (data.status == "error") {
      setErrorMessage(data);
    }
    if (data.data?.status == "success") {
      setErrorMessage({ error: "" });
      clearInterval(interval);
      setStepData(allSteps.steps[allSteps.currentStep + 1]);
      if (allSteps.currentStep == allSteps?.steps?.length - 1) {
        setAllSteps({
          ...allSteps,
          currentStep: allSteps.currentStep + 1,
          type: "preText",
        });
      } else if (allSteps.currentStep <= allSteps?.steps?.length - 1) {
        setNextStep();
        callNextTx(allSteps.steps[allSteps.currentStep + 1], persist?.routeId || txnBody.data?.data?.routeId);
      } else {
        return;
      }
    } else if (data.data?.status == "failed") {
      clearInterval(interval);
    } else if (data.data?.status == "in-progress") {
      if (errorMessage?.error?.length) {
        setDisableButton(true);
        setPersist({ ...useStore.getState()?.persist, loading: true });
        setErrorMessage({ error: "" });
      }
    }
  }
  function utf8ToHex(utf8Str) {
    return Array.from(utf8Str)
      .map((char) => char.charCodeAt(0).toString(16).padStart(2, "0"))
      .join("");
  }
  async function executeGaslessTransaction() {
    let txnEVMData = persist?.txnEvm;
    try {
      let signature;
      if (persist?.txnType == "sign-typed") signature = await signTypedDataAsync(txnEVMData);
      else {
        if (!walletClient) {
          return;
        }
        // Create a Web3Provider using window.ethereum
        const hexEthHeader = utf8ToHex("\x19Ethereum Signed Message:\n52");
        const msg = txnEVMData.message.replace(hexEthHeader, "");
        signature = await walletClient.request({
          method: "personal_sign",
          params: [msg, persist?.route?.userWalletAddress],
        });
      }

      const res = await submitSignedTxn.mutateAsync({
        routeId: persist?.routeId,
        stepId: stepData?.stepId,
        signedTxn: signature,
      });
      if (res.status == "error") {
        setErrorMessage({ error: res.message || res?.error });
        return;
      } else {
        setPersist({ ...useStore.getState()?.persist, hash: signature });
      }
    } catch (err) {
      console.log(err, "execution error in gasless");
      setErrorMessage({ error: err.message || err?.error });
    }
  }
  function handleExeTxn() {
    if (persist?.fromChain?.networkType == "sol") {
      handleTxn(persist?.amount || amount, persist?.toChain?.networkType, persist?.nextTx?.data || nextTx.data?.data);
    } else if (persist?.fromChain?.networkType == "cosmos") {
      handleCosmosTransaction(persist?.nextTx?.data);
    } else if (persist?.fromChain?.networkType == "tron") {
      handleTronTxn(persist?.nextTx?.data || nextTx.data?.data);
    } else {
      if (persist?.txnType && persist.txnType !== "on-chain") {
        let isReset = persist?.allSteps?.steps[persist?.currentStep - 1]?.gasless;
        !isReset && reset();
        executeGaslessTransaction(stepData);
      } else {
        sendTransaction({
          ...(useStore.getState()?.persist?.txnEvm || {}),
          value: persist?.txnEvm?.value ? persist?.txnEvm?.value : "0",
          gas: persist?.txnEvm?.gasLimit || null,
        });
      }
    }
  }
  function handleDisable(bool) {
    setIsTryAgain(bool);
  }
  async function handleStep() {
    if (allSteps.steps?.[allSteps?.currentStep || 0] || persist?.allSteps.steps?.[persist?.allSteps?.currentStep || 0]) {
      setDisableButton(true);
      setPersist({
        ...useStore.getState()?.persist,
        loading: true,
        isTimerStarted: true,
        statusData: null,
        isQuoteTimer: false,
        showAcceptQuote: false,

        quote: "disable",
        startTime: useStore?.getState()?.persist?.startTime || new Date().getTime(),
      });
      startTimer(true);
      handleExeTxn();
      setAllSteps((prev) => {
        return { ...prev, type: "loadingText" };
      });
    } else {
    }
  }
  function handleSkipTxn(data) {
    if (data?.data?.skipTxn === true) {
      let stepId = txnBody?.data?.data?.steps?.[allSteps.currentStep + 1] || persist?.allSteps?.steps?.[allSteps.currentStep + 1] || {};
      if (!isEmpty(stepId) && data?.data?.routeId) {
        setTimeout(() => {
          setNextStep();
          callNextTx(stepId, data?.data?.routeId);
          setDisableButton(true);
        }, 0);
      }
    } else {
      return;
    }
  }
  useEffect(() => {
    if (useStore.getState()?.persist?.nextTx?.data && isTryAgain) {
      handleStep();
      setIsTryAgain(false);
    }
  }, [useStore.getState()?.persist?.nextTx?.data]);
  async function callNextTx(stepval, routeval, isTryAgain) {
    try {
      await nextTx.mutateAsync({
        id: stepval?.stepId,
        routeId: routeval,
      });
      setStepData(stepval);
    } catch (err) {
      console.log(err);
    }
  }

  function handleErrorReset() {
    if (persist.fromChain?.networkType === "evm") {
      reset();
      setErrorMessage({ error: "" });
    } else if (persist.fromChain?.networkType === "sol") {
      resetSolData();
    } else if (persist.fromChain?.networkType === "cosmos") {
      resetCosmosData();
    } else {
      resetTronData();
    }
  }
  function handleTryAgain() {
    // count = count + 1;
    setDisableButton(true);
    setPersist({
      ...useStore.getState()?.persist,
      stepStatus: handleStepStatus({ status: "loadingText" }),
      isTryAgain: true,
      hash: null,
    });
    handleErrorMessage(false);
    handleErrorReset();
    callNextTx(persist?.allSteps?.steps[allSteps.currentStep] || allSteps.steps[allSteps.currentStep] || {}, persist?.routeId || txnBody.data?.data?.routeId, true);
  }
  useEffect(() => {
    if (persist?.stepData?.from?.chainId) {
      if (Number(persist?.stepData?.from?.chainId) !== chain?.chainId && persist?.fromChain?.networkType === "evm") {
        switchChain({
          chainId: Number(persist?.stepData?.from?.chainId || "0"),
        });
      }
    } else return;
  }, [persist?.stepData]);
  function handleUpdate() {
    setStepData({});
    setAllSteps({ steps: null, currentStep: 0 });
  }
  const isShowNewQuote = persist.showAcceptQuote && !persist.hash && persist?.allSteps?.currentStep == 0 && persist?.nextTx?.status === "success";
  return (
    <>
      <div className={`bw-pt-4 bw-h-[80%] bw-mt-2 bw-relative  ${isShowNewQuote ? "bw-pointer-events-none bw-opacity-60" : ""}`}>
        {!txnBody.isFetching && !isEmpty(persist?.stepData) ? (
          (txnBody.isSuccess && txnBody.data) || persist?.routeId ? (
            (persist?.allSteps?.steps || txnBody.data?.data?.steps)?.map((item, i, arr) => {
              return (
                <>
                  {" "}
                  <div className="bw-flex bw-items-start bw-mb-4 bw-justify-between">
                    <div className="bw-flex  bw-relative bw-items-start  bw-w-max bw-justify-center bw-gap-x-3">
                      <StepsIcons allSteps={allSteps} disableButton={disableButton} i={i} arr={arr} />
                      <Steps item={item} i={i} />
                    </div>
                    <TransactionLinkComp i={i} item={item} />
                  </div>
                </>
              );
            })
          ) : (
            <></>
          )
        ) : (
          <div className="bw-w-full bw-flex-1 bw-h-full bw-mt-[-5%] bw-flex bw-flex-col bw-justify-center bw-items-center ">
            {/* <LoaderStep /> */}
            {!errorText ? (
              <>
                <div className="bw-relative bw-pb-4">
                  <TxnLoad />
                </div>
                <p className="bw-text-2xl bw-text-center   bw-font-medium bw-text-text-secondary">Initializing Transaction...</p>
              </>
            ) : (
              <CreateTxComp />
            )}
          </div>
        )}
      </div>
      {!txnBody.isFetching && !isEmpty(persist?.stepData) ? (
        isShowNewQuote ? (
          <UpdateQuote handleUpdate={handleUpdate} startTimer={startTimer} setDisableButton={setDisableButton} reset={handleErrorReset} details={details} />
        ) : persist?.statusData?.status == "success" && allSteps.currentStep == allSteps.steps?.length ? (
          // txnBody.isSuccess || persist.isSuccess ? (
          <SuccessCard handleBack={handleBack} />
        ) : // ) : (
        //   <>Loading...</>
        // )
        isErrorMessage ? (
          persist.statusData?.status == "partial-success" ? (
            <TransactionUpdateComp handleBack={handleBack} />
          ) : (
            <TransactionFailedCTA errorText={errorText} handleGoBack={handleBack} txnTextObj={txnTextObj} handleExeTxn={handleTryAgain} handleDisable={handleDisable} />
          )
        ) : (
          <div className="bw-absolute bw-bg-background-container bw-border-t bw-pt-3 bw-border-border-primary bw-w-full bw-flex bw-flex-col bw-items-center bw-justify-center bw-bottom-0">
            <TxnStatusComp txnProcessType={txnProcessType} txnTextObj={txnTextObj} errorText={errorText} allSteps={allSteps || persist?.allSteps} />
            <TxnExeButton txnProcessType={txnProcessType} txnTextObj={txnTextObj} disableButton={disableButton} handleStep={handleStep} />
          </div>
        )
      ) : errorText.length ? (
        <div className="bw-absolute bw-bg-background-container bw-border-t bw-pt-3 bw-border-border-primary bw-w-full bw-flex bw-flex-col bw-items-center bw-justify-center bw-bottom-0">
          <HomeButton handleBack={handleBack} errorText={errorText} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default TransactionComp;
