import PortfolioChart from "./PortFolioChart";
import { useQuery } from "@tanstack/react-query";
import controllers from "../../../Actions/Controllers";
import { useEffect, useState } from "react";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { useAccount } from "wagmi";
import { useDynamicContext, useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import useWalletDetails from "../../../hooks/useWalletDetails";
import { isEmpty } from "lodash";
import PortfolioTable from "./PortfolioTable";
import SpinnerComp from "./SpinnerComp";
import useRegisterError from "../../../hooks/useRegisterError";
import { useAccount as useAccountGraz } from "graz";
export default function PortfolioData({ selectChain }) {
  const [wallet, setWallet] = useState({});
  const [chains, setChains] = useState([]);
  const { connected, address: tronAddress } = useWallet();
  const { isConnected, address } = useAccount();
  const { primaryWallet } = useDynamicContext();
  const isFullyConnected = useIsLoggedIn();
  const [hide, setHide] = useState(true);
  const { getWallet } = useWalletDetails({});
  const { registerError } = useRegisterError({});
  const { data, isConnected: isConnectedGraz } = useAccountGraz();
  function handleHide(val) {
    setHide(val);
  }
  const portfolio = useQuery({
    queryKey: [
      "portfolio",
      isConnected,
      isFullyConnected,
      connected,
      wallet,
      chains,
      hide,
    ],
    queryFn: async () => {
      let req = await controllers.fetchPortfolio({
        wallets: { ...wallet },
        chains,
        hideSmallBalances: hide,
      });
      return await req.json();
    },

    refetchOnWindowFocus: false,
    refetchOnMount: false,
    // refetchInterval: 120000,
    enabled: !isEmpty(wallet),
    cacheTime: 1000 * 60 * 60,
  });
  useEffect(() => {
    if (portfolio.data) {
      let data = portfolio.data;
      if (data.status == "error") {
        // registerError({ module: "/portfolio", error: data.error });
      }
    }
  }, [portfolio.data]);
  useEffect(() => {
    if (portfolio.isError) {
      let error = portfolio.error?.details || portfolio.error?.message || "";
      // registerError({ module: "/portfolio", error });
    }
  }, [portfolio.isError]);
  useEffect(() => {
    let tempWallet = {};
    let chainsArray = [];
    if (selectChain[0].name == "All Chains" && selectChain.length <= 1) {
      if (connected) tempWallet.tron = [tronAddress];

      if (isConnected) tempWallet.evm = [address];

      if (isFullyConnected && primaryWallet)
        tempWallet.sol = [primaryWallet?.address];
      if (isConnectedGraz) tempWallet.cosmos = [data?.bech32Address];
    } else {
      selectChain.forEach((item) => {
        if (
          item.networkType &&
          typeof tempWallet[item.networkType] === "undefined" &&
          getWallet(item)?.address
        ) {
          tempWallet[item.networkType] = [getWallet(item)?.address];
        }
        if (item.chainId) chainsArray.push(item.chainId);
      });
    }
    setWallet({ ...tempWallet });
    setChains([...chainsArray]);
  }, [selectChain, isFullyConnected, primaryWallet, isConnected, connected]);
  return (
    <>
      {!portfolio.isLoading ? (
        <>
          <PortfolioChart
            selectChain={selectChain}
            data={portfolio.data?.data || []}
            portfolio={portfolio}
          />
          <PortfolioTable
            data={portfolio.data?.data?.assets || []}
            hide={hide}
            setHide={handleHide}
          />
        </>
      ) : (
        <SpinnerComp />
      )}
    </>
  );
}
