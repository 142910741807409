export default function ShareIcon({ fill }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 2H2C1.44772 2 1 2.44772 1 3V11C1 11.5523 1.44772 12 2 12H10C10.5523 12 11 11.5523 11 11V7"
        stroke={fill}
        stroke-linecap="round"
      />
      <path
        d="M4.64645 8.14645C4.45118 8.34171 4.45118 8.65829 4.64645 8.85355C4.84171 9.04882 5.15829 9.04882 5.35355 8.85355L4.64645 8.14645ZM13 1C13 0.723858 12.7761 0.5 12.5 0.5H8C7.72386 0.5 7.5 0.723858 7.5 1C7.5 1.27614 7.72386 1.5 8 1.5H12V5.5C12 5.77614 12.2239 6 12.5 6C12.7761 6 13 5.77614 13 5.5V1ZM5.35355 8.85355L12.8536 1.35355L12.1464 0.646447L4.64645 8.14645L5.35355 8.85355Z"
        fill={fill}
      />
    </svg>
  );
}
