import React, { useEffect, useState, useRef } from "react";
import SelectChain from "../SelectChain";
import useStore from "../../zustand/store";
import { useAccount } from "wagmi";
import { useQuery } from "@tanstack/react-query";
import controllers from "../../Actions/Controllers";
import LoadRoute from "./LoadRoute";
import AllRoutes from "./AllRoutes";
import ConfirmDetails from "../ConfirmDetails";
import SelectWallet from "../SelectWallet";
import { useDynamicContext, useUserWallets } from "@dynamic-labs/sdk-react-core";
import CoinCard from "./CoinCard";
import Header from "./Header";
import SwapAsset from "./SwapAsset";
import InputComp from "./InputComp";
import QuotesProgress from "./QuotesProgress";
import TxnButton from "./TxnButton";
import useRoutes from "../../hooks/useRoutes";
import useFromWallet from "../../hooks/useFromWallet";
import useWalletDetails from "../../hooks/useWalletDetails";
import useQuoteTimer from "../../hooks/useQuoteTimer";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import useSolBalance from "../../hooks/useSolBalance";
import useGetBalance from "../../hooks/useGetBalance";
import { keyBy } from "lodash";
import getCosmosAddress from "../../utils/getCosmosAddress";
import { useAccount as useAccountCosmos, WalletType, useSuggestChain, useSuggestChainAndConnect, getWallet } from "graz";
import getChainIds from "../../utils/getChainIds";
import { evmos } from "viem/chains";
export default function WidgetForm({ handleShowWallet, showWallet, fetchChains }) {
  const [showExchangeList, setShowExchangeList] = useState();
  const [showAllRoutes, setShowAllRoutes] = useState(false);
  const [confirmRoute, setConfirmRoute] = useState(false);
  const [stopRoute, setStopRoute] = useState(false);
  const [isAmountTyped, setIsAMountTyped] = useState(false);
  const [connectWalletField, setConnectWalletField] = useState("all");
  const [isTransactionSuccessful, setIsTransactionSuccessful] = useState(false);
  //wallet hooks
  const { primaryWallet } = useDynamicContext();

  const wallets = useUserWallets();
  const { address } = useAccount();
  const { address: tronAddress } = useWallet();
  const { getbal, isBalanceLoading } = useGetBalance(isTransactionSuccessful);
  const { data, isConnected } = useAccountCosmos({ chainId: "cosmoshub-4" });

  //zustand
  const configuration = useStore((state) => state.configuration);
  const persist = useStore((state) => state.persist);
  const setPersist = useStore((state) => state.setPersist);
  const route = useStore((state) => state.route);
  const fromChain = useStore((state) => state.fromChain);
  const toChain = useStore((state) => state.toChain);
  const fromCoin = useStore((state) => state.fromCoin);
  const toCoin = useStore((state) => state.toCoin);
  const setFromChain = useStore((state) => state.setFromChain);
  const setToChain = useStore((state) => state.setToChain);
  const setFromCoin = useStore((state) => state.setFromCoin);
  const setToCoin = useStore((state) => state.setToCoin);
  const amount = useStore((state) => state.amount);
  const setAmount = useStore((state) => state.setAmount);
  const customWallet = useStore((state) => state.customWallet);
  const setToDisconnect = useStore((state) => state.setToDisconnect);
  const setCosmosChainIds = useStore((state) => state.setCosmosChainIds);
  const customFee = useStore((state) => state.customFee);
  const broadCastMode = useStore((state) => state.broadCastMode);
  const evmGas = useStore((state) => state.evmGas);
  const evmSpeed = useStore((state) => state.evmSpeed);
  const slippage = useStore((state) => state.slippage);
  const option = useStore((state) => state.option);
  const [feeBps, setFeeBps] = useState("");
  const gasless = useStore((state) => state.gasless);
  const setGasless = useStore((state) => state.setGasless);
  const setCustomWallet = useStore((state) => state.setCustomWallet);
  const { balanceSol, getBalanceSol } = useSolBalance(fromChain, isTransactionSuccessful, handleSuccess);
  const { suggestAndConnectAsync } = useSuggestChainAndConnect();
  const isRoutesEnabled = amount.length && fromChain.chain.length && fromCoin.coin.length && toChain.chain.length && toCoin.coin.length && !stopRoute && isAmountTyped ? true : false;
  const routesDep = ["routes", address, fromChain, toChain, fromCoin, toCoin, amount, primaryWallet?.address, tronAddress, customWallet, data?.bech32Address, customFee, evmGas, evmSpeed, slippage, broadCastMode, option.value, feeBps, gasless];
  let solanaPriorityFee = (broadCastMode === "Priority Fee" || broadCastMode === "Both") && customFee?.length ? customFee : option.value;
  let solanaJitoTip = (broadCastMode === "Jito Tip" || broadCastMode === "Both") && customFee?.length ? customFee : option.value;
  let evmPriorityFee = evmGas?.length ? evmGas : evmSpeed.value;
  const feeQString = fromChain?.networkType === "sol" ? (broadCastMode === "Priority Fee" ? `&solanaPriorityFee=${solanaPriorityFee}` : broadCastMode === "Jito Tip" ? `&solanaJitoTip=${solanaJitoTip}` : `&solanaPriorityFee=${solanaPriorityFee}&solanaJitoTip=${solanaJitoTip}`) : fromChain?.networkType === "evm" ? `&evmPriorityFee=${evmPriorityFee}` : "";
  //custom hooks src->hooks
  const { getWallet, refetch } = useWalletDetails({});
  const { routes, routesData, setRoutesData, errorMessage, setErrorMessage } = useRoutes({
    enabled: isRoutesEnabled,
    dependency: routesDep,
    payload: [
      fromChain?.networkType === "cosmos" ? getCosmosAddress(data?.bech32Address, fromChain?.chainInfo?.bech32Config.bech32PrefixAccAddr) : getWallet(fromChain)?.address || "",
      fromChain,
      toChain,
      fromCoin,
      toCoin,
      amount,
      Number(slippage) * 100,
      customWallet?.length > 30 ? getWallet({})?.address || "" : toChain?.networkType === "cosmos" ? getCosmosAddress(data?.bech32Address, toChain?.chainInfo?.bech32Config.bech32PrefixAccAddr) : getWallet(toChain)?.address || "",
      feeQString,
      feeBps,
      gasless,
    ],
  });
  const fromWalletAddress = getWallet(fromChain);
  const fetchBalance = useQuery({
    queryKey: ["balance", fromWalletAddress?.address, fromChain],
    queryFn: async () => {
      let res = await controllers.fetchBalance(fromWalletAddress.address, fromChain.chainId);
      return await res.json();
    },

    enabled: fromWalletAddress?.address?.length && fromChain.chainId ? true : false,
    refetchOnWindowFocus: false,
  });

  const { quoteTimer } = useQuoteTimer({
    routes,
    routesData,
    confirmRoute,
    setConfirmRoute,
    isRoutesEnabled,
    fetchBalance,
  });
  useEffect(() => {
    if (!amount) {
      setRoutesData({});
    }
    let debounce;
    if (Number(amount) > 0) {
      debounce = setTimeout(() => {
        setIsAMountTyped(true);
      }, 500);
    }

    return () => {
      clearTimeout(debounce);
      setIsAMountTyped(false);
    };
  }, [amount]);
  useEffect(() => {
    if (routes.isFetching) {
      setRoutesData({});
    }
  }, [routes.isFetching]);
  function handleStopRoute(val) {
    setStopRoute(val);
  }

  function handleResetList() {
    setShowExchangeList();
  }
  async function checkChainAvailability(chainInfo) {
    if (isConnected) {
      try {
        let chainIds = await getChainIds();
        if (chainIds.length && !chainIds.includes(chainInfo.chainId)) {
          let req = await suggestAndConnectAsync({
            chainInfo: chainInfo,
            walletType: WalletType.KEPLR,
          });
          let refetchChains = await refetch();
          let chainIds1 = await getChainIds();
          setCosmosChainIds(chainIds1);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      return;
    }
  }
  function handleChain(data) {
    if (data?.networkType == "cosmos") {
      checkChainAvailability(data.chainInfo);
    }
    setAmount("");
    setErrorMessage({ error: "" });
    if (showExchangeList == "from") {
      setFromChain({ ...data });
    } else if (showExchangeList == "to") {
      setToChain({ ...data });
    }
  }

  function handleCoin(data) {
    setErrorMessage({ error: "" });
    setAmount("");
    if (showExchangeList == "from") {
      setFromCoin({ ...data });
    } else if (showExchangeList == "to") {
      setToCoin({ ...data });
    }
  }

  function handleShowAllRoutes() {
    setShowAllRoutes(!showAllRoutes);
  }

  function handleConfirmClose(val) {
    // if (!isEmpty(routesData)) {
    setConfirmRoute(typeof val !== "undefined" ? val : !confirmRoute);
    // }
  }
  function handleSuccess(val) {
    setIsTransactionSuccessful(val);
  }
  function handleRoutesData(data) {
    setRoutesData(data);
  }
  function resetBalance() {
    setTimeout(() => {
      handleSuccess(true);
      fetchBalance.refetch();
    }, 1000);
  }
  function resetAmount() {
    setAmount("");
  }
  function fetchTokens(item, setCoin, config) {
    controllers
      .fetchTokens(item.chainId, config)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        res.data?.[item.chainId]?.forEach((item) => {
          setCoin({
            ...fromCoin,
            coin: item.symbol,
            ...item,
            availBal: "",
          });
        });
      });
  }

  function handleSwap() {
    setAmount("");
    setFromChain(toChain);
    setFromCoin(toCoin);
    setToChain(fromChain);
    setToCoin(fromCoin);
    fromChain.networkType !== toChain.networkType && setCustomWallet("");
    // setToDisconnect(false);
  }
  useEffect(() => {
    if (persist.isHistory && route === "") {
      setPersist({});
      handleStopRoute(false);
    }
  }, [persist]);
  function handleRecentSelectedWallet(recentConnectedWallet) {
    if (recentConnectedWallet && fetchChains.isSuccess && recentConnectedWallet !== toChain?.networkType && recentConnectedWallet !== fromChain?.networkType) {
      let chainsObj = keyBy(fetchChains.data.data, "chainId");
      let chainId = recentConnectedWallet == "evm" ? "8453" : recentConnectedWallet == "sol" ? "sol" : "cosmoshub-4";

      let tokenAddress = recentConnectedWallet == "evm" ? "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" : recentConnectedWallet == "sol" ? "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263" : "uatom";
      setFromChain({
        ...chainsObj[chainId],
        chain: chainsObj[chainId]?.name || "",
      });
      fetchTokens(chainsObj[chainId], setFromCoin, tokenAddress);
    } else {
      return;
    }
  }

  return (
    <div>
      {showWallet ? (
        <SelectWallet connectWalletField={connectWalletField} handleShowWallet={handleShowWallet} handleRecentSelectedWallet={handleRecentSelectedWallet} />
      ) : !confirmRoute && !Boolean(persist?.routeId?.length) ? (
        !showAllRoutes ? (
          !showExchangeList ? (
            <>
              <Header />
              <div className="bw-flex bw-relative  bw-items-center bw-gap-x-2 bw-justify-between">
                <SwapAsset handleSwap={handleSwap} />
                <CoinCard chainData={fromChain} show={"from"} handleShowWallet={handleShowWallet} setShowExchangeList={setShowExchangeList} setConnectWalletField={setConnectWalletField} coinData={fromCoin} />
                <CoinCard chainData={toChain} show={"to"} handleShowWallet={handleShowWallet} setShowExchangeList={setShowExchangeList} setConnectWalletField={setConnectWalletField} coinData={toCoin} />
              </div>
              <div className="bw-flex bw-items-end bw-gap-x-2">
                <div className="bw-w-[70%]">
                  <p className="bw-text-sm bw-font-bold bw-text-text-secondary bw-py-1">Fee </p>
                  <input type="number" className="bw-w-full bw-border bw-bg-background-card bw-border-border-primary bw-text-text-primary bw-rounded-md bw-p-2" value={feeBps} onChange={(e) => setFeeBps(e.target.value)} />
                </div>
                <div className="bw-w-[30%] bw-flex bw-items-center bw-gap-x-2">
                  <input onChange={(e) => setGasless(e.target.checked)} type="checkbox" checked={gasless} className="bw-bg-background-container bw-border bw-border-border-primary w-[20px] h-[20px] bw-text-text-primary bw-rounded-md bw-p-2" />
                  <p className="bw-text-sm bw-font-bold bw-text-text-secondary bw-py-1">Gasless </p>
                </div>
              </div>
              <InputComp amount={amount} setAmount={setAmount} fetchBalance={fetchBalance} getCoinBalance={getbal} isBalanceLoading={isBalanceLoading} />

              <div>
                <LoadRoute routes={routes} handleShowAllRoutes={handleShowAllRoutes} routesData={routesData} amount={amount} isRoutesEnabled={isAmountTyped} price={(routesData?.outputAmountDisplay || "") * (toCoin?.lastPrice || 0)} />
              </div>
              <p className="bw-text-xs bw-font-normal bw-py-1 bw-text-text-redtext">{typeof errorMessage?.error == "string" ? errorMessage?.error : ""}</p>
              <QuotesProgress routes={routes} routesData={routesData} confirmRoute={confirmRoute} setConfirmRoute={setConfirmRoute} isRoutesEnabled={isRoutesEnabled} quoteTimer={quoteTimer} />
              <TxnButton routesData={routesData} handleSuccess={handleSuccess} routes={routes} fetchBalancePrev={fetchBalance} amount={amount} isAmountTyped={isAmountTyped} handleShowWallet={handleShowWallet} setConnectWalletField={setConnectWalletField} handleConfirmClose={handleConfirmClose} balanceSol={balanceSol} getCoinBalance={getbal} />
            </>
          ) : (
            <SelectChain chainData={showExchangeList == "from" ? fromChain : toChain} coinData={showExchangeList == "from" ? fromCoin : toCoin} setChainData={handleChain} setCoinData={handleCoin} handleReset={handleResetList} showExchangeList={showExchangeList} fetchBalance={fetchBalance} fetchChains={fetchChains} />
          )
        ) : (
          <AllRoutes routes={routes} handleShowAllRoutes={handleShowAllRoutes} handleRoutesData={handleRoutesData} convertVal={toCoin?.lastPrice || 0} amount={amount} />
        )
      ) : (
        <ConfirmDetails handleConfirmClose={handleConfirmClose} amount={amount} routesData={routesData} resetAmount={resetAmount} routes={routes} handleStopRoute={handleStopRoute} resetBalance={resetBalance} />
      )}
    </div>
  );
}
