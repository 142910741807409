import { getWallet, WalletType } from "graz";
import { keyBy } from "lodash";
import useStore from "../zustand/store";
async function getChainIds() {
  const keplr = getWallet(WalletType.KEPLR);
  let getChains = await keplr.getChainInfosWithoutEndpoints();
  let getCosmosChains = useStore.getState()?.cosmosChains || [];
  const chainObj = keyBy(getChains, "chainId");
  let chainIds = [];
  getCosmosChains.forEach((item) => {
    if (chainObj[item.chainId]) chainIds.push(item.chainId);
    else {
      return;
    }
  });
  return chainIds;
}

export default getChainIds;
