import truncate from "../../utils/truncate";
import { CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import controllers from "../../Actions/Controllers";
import useWalletDetails from "../../hooks/useWalletDetails";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { useAccount } from "wagmi";
import { useDynamicContext, useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import ShareIcon from "../Svg/shareicon";
import useStore from "../../zustand/store";
import images from "../../images";
import { useInView } from "react-intersection-observer";
import React, { useEffect, useState, useRef } from "react";
import getAddressType from "../../utils/getAddressType";
import useCosmosBalance from "../../hooks/useCosmosBalance";
import { useAccount as useGrazAccount } from "graz";
import parseAddress from "../../utils/parseAddress";
import formatNumber from "../../utils/formatNumber";
import fixString from "../../utils/fixString";
import TokenEmptyState from "./TokenEmptyState";
import { FormatCryptoValue } from "../../utils/formatCryptoValue";
export default React.memo(function Token({
  selectChainState,
  value,
  handleClosePopup,
  showExchangeList,
  setCoinData,
  setChainData,
}) {
  // const [searchCustomCoin, setSearchCustomCoin] = useState("");
  const searchCustomCoin = useStore((state) => state.searchCustomCoin);
  const setSearchCustomCoin = useStore((state) => state.setSearchCustomCoin);
  const { ref, inView } = useInView();
  const { getWallet } = useWalletDetails({});
  const { connected, address: tronAddress } = useWallet();
  const { isConnected, address } = useAccount();
  const { primaryWallet } = useDynamicContext();
  const isFullyConnected = useIsLoggedIn();
  const fromWalletAddress = getWallet(selectChainState);
  const fromChain = useStore((state) => state.fromChain);
  const fromCoin = useStore((state) => state.fromCoin);
  const toChain = useStore((state) => state.toChain);
  const toCoin = useStore((state) => state.toCoin);
  const [isCustomSearching, setIsCustomSearching] = useState(false);
  const coinData = showExchangeList == "from" ? fromCoin : toCoin;
  const { cosmosBalance } = useCosmosBalance(selectChainState);
  const { defaultCoin } = images;
  const [page, setPage] = useState(0);
  const scrollRef = useRef(null);
  const { data } = useGrazAccount({
    chainId: [selectChainState.chainId],
    multiChain: true,
  });
  const isWalletConnected =
    (selectChainState?.networkType === "evm" && address) ||
    (selectChainState?.networkType === "sol" && primaryWallet?.address) ||
    (selectChainState?.networkType === "tron" && tronAddress) ||
    (selectChainState?.networkType === "cosmos" &&
      data?.[selectChainState?.chainId]?.bech32Address);
  const toDisconnect = useStore((state) => state.toDisconnect);
  const setToDisconnect = useStore((state) => state.setToDisconnect);
  const customWallet = useStore((state) => state.customWallet);
  const setCustomWallet = useStore((state) => state.setCustomWallet);
  const tokens = useStore((state) => state.tokens);
  const [tokensArray, setTokensArray] = useState([]);
  const [displayAray, setDisplayArray] = useState([]);
  const [filterArray, setFilterArray] = useState([]);
  const fetchTokens = tokens;
  const fetchBalance = useQuery({
    queryKey: ["balance", fromWalletAddress?.address, selectChainState],
    queryFn: async () => {
      let res = await controllers.fetchBalance(
        fromWalletAddress.address,
        selectChainState.chainId
      );
      return await res.json();
    },

    enabled:
      fromWalletAddress?.address?.length && selectChainState.chainId
        ? true
        : false,
    refetchOnWindowFocus: false,
  });
  function handleSort(val) {
    let cosmosAddress = parseAddress(val.address);

    return val.networkType == "cosmos"
      ? formatNumber(
          cosmosBalance?.[cosmosAddress]?.amount / Math.pow(10, val.decimals) ||
            0
        ) || ""
      : formatNumber(
          (fetchBalance.data?.data?.[selectChainState?.networkType]?.[
            selectChainState.chainId
          ]?.[val.address]?.balance || 0) /
            Math.pow(
              10,
              fetchBalance.data?.data?.[selectChainState?.networkType]?.[
                selectChainState.chainId
              ]?.[val.address]?.asset?.decimals || 0
            ) || 0
        ) || "";
  }
  useEffect(() => {
    if (value.length > 40 && getAddressType(value) && !displayAray.length) {
      setIsCustomSearching(true);
      setSearchCustomCoin({
        chainId: selectChainState.chainId,
        address: value,
      });
    } else if (!value.length) {
      setIsCustomSearching(false);
      setSearchCustomCoin({ chainId: "", address: "" });
    }
  }, [value, displayAray]);
  useEffect(() => {
    setPage(0);
  }, [value]);
  function resetScroll() {
    setPage(0);
    setDisplayArray([]);
    scrollRef.current.scrollTo(0, 0);
  }
  useEffect(() => {
    if (fetchTokens?.isSuccess && selectChainState?.chainId) {
      setTokensArray(
        fetchTokens?.data?.data?.[selectChainState?.chainId] || []
      );
    }
  }, [fetchTokens, selectChainState.chainId]);
  useEffect(() => {
    resetScroll();
  }, [selectChainState.chainId]);
  function loadItem() {
    let filterArray = tokensArray
      ?.filter((item) => {
        return (
          item.name?.toLowerCase().includes(value?.toLowerCase()) ||
          item.symbol?.toLowerCase().includes(value?.toLowerCase()) ||
          item.address?.toLowerCase()?.includes(value?.toLowerCase())
        );
      })
      ?.sort((a, b) => {
        if (isWalletConnected)
          return handleSort(b) * b.lastPrice - handleSort(a) * a.lastPrice;
        else return;
      });
    let noOfItems = 20;
    let startIndex = page * noOfItems;
    let endIndex = startIndex + noOfItems;
    let arr = filterArray.slice(startIndex, endIndex);
    if (page == 0) {
      setDisplayArray([...arr]);
    } else setDisplayArray([...displayAray, ...arr]);
  }

  useEffect(() => {
    if (tokensArray?.length) {
      loadItem();
    }
  }, [
    tokensArray,
    value,
    page,
    isWalletConnected,
    cosmosBalance,
    fetchBalance.data,
  ]);
  useEffect(() => {
    if (inView) {
      setPage((prev) => prev + 1);
    }
  }, [inView]);
  return (
    <div
      ref={scrollRef}
      className=" bw-h-[200px] bw-pr-2 md:bw-h-[380px] bw-mt-2 bw-overflow-y-auto"
    >
      {displayAray && displayAray?.length > 0 ? (
        displayAray?.map((item, idx, array) => {
          return (
            <div
              ref={array.length >= 20 && array.length - 1 === idx ? ref : null}
              key={idx}
              className={`bw-py-2
                  ${
                    showExchangeList == "from" && toCoin._id !== item._id
                      ? ""
                      : showExchangeList == "to" && fromCoin._id !== item._id
                      ? ""
                      : ""
                    // "bw-pointer-events-none bw-opacity-60"
                  }
                  bw-flex bw-items-center  bw-justify-between bw-cursor-pointer bw-border-b bw-border-border-primary
                   
                  }`}
              onClick={async () => {
                let newObj = {
                  // ...coinData,
                  coin: item.symbol,
                  ...item,
                  availBal:
                    fetchBalance.data?.data?.[selectChainState?.networkType]?.[
                      selectChainState.chainId
                    ]?.[item.address.toLowerCase()]?.balance /
                    Math.pow(
                      10,
                      fetchBalance.data?.data?.[
                        selectChainState?.networkType
                      ]?.[selectChainState?.chainId]?.[
                        item.address.toLowerCase()
                      ]?.asset?.decimals || 0
                    ),
                };

                setCoinData(newObj);
                setChainData(selectChainState);
                handleClosePopup(selectChainState, newObj);
                if (selectChainState?.networkType === toChain?.networkType) {
                  // setToDisconnect(false);
                } else if (
                  selectChainState?.networkType === fromChain?.networkType
                ) {
                  // setToDisconnect(false);
                  setCustomWallet("");
                } else {
                  // setToDisconnect(true);
                  setCustomWallet("");
                }
              }}
            >
              <div className="bw-flex bw-items-center bw-justify-center bw-w-max bw-gap-x-2">
                <div className="bw-relative bw-rounded-[50%] bw-bg-background-coin bw-w-[30px]  bw-h-[30px]">
                  {item.image ? (
                    <img
                      src={item.image}
                      className="bw-rounded-[50%] bw-max-w-[30px] bw-max-h-[30px]  bw-object-contain"
                      width={30}
                      height={30}
                      alt="img"
                    />
                  ) : (
                    <img
                      src={defaultCoin}
                      className="bw-rounded-[50%]"
                      width={30}
                      height={30}
                      alt="img"
                    />
                  )}
                  {selectChainState?.image ? (
                    <img
                      src={selectChainState.image}
                      className={
                        "bw-absolute bw-bg-background-coin bw-right-0 bw-max-w-[16px] bw-max-h-[16px]  bw-object-contain bw-bottom-[-4px] bw-border bw-border-border-primary bw-rounded-[50%]"
                      }
                      width={16}
                      height={16}
                      alt="img"
                    />
                  ) : (
                    <img
                      src={defaultCoin}
                      className={
                        "bw-absolute bw-bg-background-coin bw-right-0 bw-bottom-[-4px] bw-border bw-border-border-primary bw-rounded-[50%]"
                      }
                      width={16}
                      height={16}
                      alt="img"
                    />
                  )}
                </div>
                <div>
                  <p className="bw-text-sm bw-font-normal bw-text-text-secondary">
                    <span className="bw-font-bold bw-text-text-primary">
                      {item.symbol || ""}{" "}
                    </span>

                    {item.name}
                  </p>
                  <div className="bw-flex bw-items-center bw-gap-x-2">
                    <p className="bw-text-xs bw-font-normal  bw-text-text-secondary">
                      {selectChainState.name}
                    </p>

                    <div className="bw-bg-background-networkcard  bw-flex bw-items-center bw-gap-x-2 bw-px-2">
                      <p className="bw-text-xs bw-font-normal bw-text-text-secondary">
                        {item?.address
                          ? item.address.substring(0, 6) +
                            "..." +
                            item.address.substring(
                              item.address.length - 6,
                              item.address.length
                            )
                          : ""}
                      </p>
                      <a
                        href={selectChainState.explorer?.token?.replace(
                          "{tokenAddress}",
                          item.address
                        )}
                        target="_blank"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="hover:bw-opacity-70"
                      >
                        <ShareIcon fill={"var(--tw-text-secondary)"} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p className="bw-text-sm bw-text-right bw-font-medium bw-text-text-primary">
                  {showExchangeList === "to" &&
                  customWallet &&
                  (customWallet?.length > 30 || toDisconnect) ? (
                    <></>
                  ) : !fetchBalance.isFetching ? (
                    isWalletConnected ? (
                      handleSort(item) > 0 ? (
                        <>
                          <FormatCryptoValue
                            value={handleSort(item) || ""}
                            options={{
                              maxDecimals: 10,
                            }}
                          />

                          <p className="bw-text-text-secondary bw-font-normal bw-text-xs">
                            {handleSort(item) * item.lastPrice > 0 ? (
                              <>
                                {`$`}
                                <FormatCryptoValue
                                  value={formatNumber(
                                    handleSort(item) * item.lastPrice
                                  )}
                                  options={{
                                    maxDecimals: 10,
                                  }}
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </p>
                        </>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )
                  ) : (
                    <CircularProgress size={12} thickness={4} color="inherit" />
                  )}
                </p>
              </div>
            </div>
          );
        })
      ) : isCustomSearching && !(tokens.isLoading && tokens.isFetching) ? (
        <TokenEmptyState selectedChain={selectChainState} />
      ) : (
        !value.length && (
          <div className="bw-w-full bw-flex  bw-justify-center bw-mt-4 bw-text-text-primary">
            <CircularProgress color="inherit" size={30} />
          </div>
        )
      )}
    </div>
  );
});
