export default function TokenSearch({ fill }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3012 14.8733C19.1754 13.4091 19.6377 11.7359 19.6393 10.0306C19.6393 8.14023 19.0788 6.29229 18.0285 4.7205C16.9783 3.1487 15.4855 1.92363 13.7391 1.20022C11.9926 0.476798 10.0708 0.287518 8.21673 0.656314C6.36267 1.02511 4.6596 1.93542 3.3229 3.27212C1.9862 4.60882 1.07589 6.31189 0.707095 8.16595C0.3383 10.02 0.527579 11.9418 1.251 13.6883C1.97442 15.4348 3.19948 16.9275 4.77128 17.9778C6.34308 19.028 8.19101 19.5886 10.0814 19.5886C11.7867 19.5869 13.4599 19.1246 14.9241 18.2504L20.4518 23.7781C20.7867 24.1099 21.2121 24.3355 21.6747 24.4264C22.1373 24.5174 22.6164 24.4697 23.052 24.2893C23.4876 24.1089 23.8602 23.8039 24.1231 23.4126C24.3861 23.0213 24.5275 22.561 24.5298 22.0896C24.528 21.4563 24.2761 20.8494 23.8289 20.401L18.3012 14.8733ZM10.0814 17.9956C8.50607 17.9956 6.96613 17.5284 5.6563 16.6532C4.34647 15.778 3.32558 14.5341 2.72273 13.0787C2.11988 11.6233 1.96215 10.0218 2.26948 8.47672C2.57681 6.93167 3.3354 5.51245 4.44932 4.39853C5.56324 3.28462 6.98245 2.52603 8.5275 2.2187C10.0726 1.91137 11.674 2.0691 13.1294 2.67195C14.5849 3.2748 15.8288 4.29569 16.704 5.60552C17.5792 6.91535 18.0463 8.45529 18.0463 10.0306C18.0442 12.1424 17.2044 14.1671 15.7111 15.6603C14.2179 17.1536 12.1932 17.9934 10.0814 17.9956ZM22.6979 22.6471C22.6242 22.7209 22.5367 22.7795 22.4403 22.8195C22.344 22.8595 22.2407 22.88 22.1364 22.88C22.032 22.88 21.9287 22.8595 21.8324 22.8195C21.736 22.7795 21.6485 22.7209 21.5748 22.6471L16.2463 17.3185C16.6518 16.9767 17.0275 16.601 17.3693 16.1955L22.6979 21.532C22.7734 21.6039 22.8335 21.6904 22.8746 21.7863C22.9156 21.8821 22.9368 21.9853 22.9368 22.0896C22.9368 22.1938 22.9156 22.297 22.8746 22.3928C22.8335 22.4887 22.7734 22.5752 22.6979 22.6471Z"
        fill={fill}
      />
      <path
        d="M10.2035 11.0835C10.0989 11.0835 9.99531 11.0629 9.89867 11.0229C9.80202 10.9829 9.7142 10.9242 9.64024 10.8502C9.56627 10.7763 9.5076 10.6885 9.46758 10.5918C9.42756 10.4952 9.40698 10.3916 9.40701 10.287V5.81212C9.40701 5.60088 9.49092 5.39829 9.6403 5.24892C9.78967 5.09954 9.99226 5.01562 10.2035 5.01562C10.4147 5.01562 10.6173 5.09954 10.7667 5.24892C10.9161 5.39829 11 5.60088 11 5.81212V10.287C11 10.3916 10.9794 10.4952 10.9394 10.5918C10.8994 10.6885 10.8407 10.7763 10.7668 10.8502C10.6928 10.9242 10.605 10.9829 10.5083 11.0229C10.4117 11.0629 10.3081 11.0835 10.2035 11.0835Z"
        fill={fill}
      />
      <path
        d="M10.1919 14.4639C9.64201 14.4639 9.19626 14.0181 9.19626 13.4683C9.19626 12.9184 9.64201 12.4727 10.1919 12.4727C10.7417 12.4727 11.1875 12.9184 11.1875 13.4683C11.1875 14.0181 10.7417 14.4639 10.1919 14.4639Z"
        fill={fill}
      />
    </svg>
  );
}
