import React from "react";
import TokenSearch from "../Svg/tokenSearch.js";

function TokenEmptyState({ selectedChain }) {
  return (
    <div className="bw-h-[100%] bw-flex bw-flex-col bw-justify-center bw-items-center">
      <div className="bw-flex bw-justify-center bw-items-center">
        <div className="bw-relative">
          {selectedChain?.image ? (
            <img
              src={selectedChain?.image}
              className="bw-w-[80px] bw-h-[80px] bw-border-border-primary bw-border-2 bw-opacity-50 bw-rounded-[50%]"
              alt="chain"
            />
          ) : (
            <></>
          )}
          <div className="bw-absolute bw-bg-background-container -bw-bottom-2 -bw-right-2 bw-flex bw-items-center bw-justify-center bw-rounded-full bw-border bw-border-border-primary bw-w-[44px] bw-h-[44px]">
            <TokenSearch fill={"var(--tw-text-secondary)"} />
          </div>
        </div>
      </div>
      <h2 className="bw-text-base bw-text-center bw-mt-3 bw-font-bold bw-text-text-primary">
        No Tokens found on {selectedChain?.chain || ""}!
      </h2>
      <p className="bw-text-sm bw-leading-6 bw-font-semibold bw-text-text-secondary bw-text-center">
        Please check if the contract address is correct or
        <br /> try searching on other chains.
      </p>
    </div>
  );
}

export default TokenEmptyState;
