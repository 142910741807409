import React, { useEffect, useState, useContext } from "react";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import useStore from "../../zustand/store";
import { TxnErrorContext } from "../Context/txnErrorContext";
let interval;
function QuoteTimer() {
  const configuration = useStore((state) => state.configuration);
  const persist = useStore((state) => state.persist);
  const setPersist = useStore((state) => state.setPersist);
  const { isErrorMessage } = useContext(TxnErrorContext);
  const time = Math.floor(
    (new Date().getTime() - persist.quoteStartTime) / 1000
  );
  const [timer, setTimer] = useState(60);
  useEffect(() => {
    if (time) {
      setTimer(60 - time);
    }
  }, []);
  useEffect(() => {
    interval = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 0) {
          clearInterval(interval);
          setPersist({
            ...useStore.getState()?.persist,
            isQuoteTimer: false,
            showAcceptQuote: true,
            quoteStartTime: new Date().getTime(),
          });
          return 0;
        } else {
          return prev - 1;
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (isErrorMessage || !persist.isQuoteTimer) {
      clearInterval(interval);
    }
  }, [isErrorMessage, persist.isQuoteTimer]);
  return !persist?.hash && persist.quote === "enable" ? (
    <div className="bw-absolute bw-top-0 bw-right-0 ">
      <svg className="bw-absolute bw-w-[20px] bw-h-[20px]">
        <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
          <stop
            offset="1%"
            stopColor={
              configuration?.gradientStyle?.spinnerColor
                ? configuration?.gradientStyle?.spinnerColor
                : "#A45EFF"
            }
          />
          <stop
            offset="90%"
            stopColor={
              configuration?.gradientStyle?.spinnerColor
                ? configuration?.gradientStyle?.stopColor
                : "#2CFFE4"
            }
          />
        </linearGradient>
      </svg>
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
        }}
      >
        <CircularProgress
          variant="determinate"
          sx={{
            color: "var(--tw-border-primary)",
          }}
          size={20}
          thickness={2}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          size={20}
          thickness={5}
          value={(timer / 60) * 100}
          sx={{
            "svg circle": { stroke: "url(#linearColors)" },
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
        />
      </Box>
    </div>
  ) : (
    <></>
  );
}

export default QuoteTimer;
