export default function formatNumber(number, decimal = 20) {
  return Number(number).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimal,
    useGrouping: false,
  });
}
export function formatNumberWithGrouping(number, decimal = 20) {
  return Number(number).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimal,
    useGrouping: true,
  });
}
